import { Injectable } from '@angular/core';
import firebase from 'firebase';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  private db = firebase.firestore();
  constructor(private authService: AuthService) { }

  async offerInvoiceVisibilitySetIssuedStatus(offerId, campaignKey, affiliateId, affiliateRefId, campaignStartDate, isInvoiceIssued, isVisibleForaffiliate, isCustomAdded) {

    if (!affiliateRefId || affiliateRefId === "") {
      return;
    }

    const offerStats = (await this.db.collection("campaign_offer").doc(campaignKey + "-" + offerId).get()).data();

    const invoiceIssuedWhen = new Date();

    const year = campaignKey.substr(campaignKey.length - 4);
    const campaignMonth = campaignStartDate.substring(5, 7);
    const affiliateCustomRef = await this.db.collection("affiliate_custom").doc(affiliateRefId).collection("month_year").doc(`${year}00${campaignMonth}`);

    const affiliateCustom = (await affiliateCustomRef.get()).data();

    if (!isCustomAdded) {
      if (!offerStats.campaign_affiliates_custom) {
        offerStats.campaign_affiliates_custom = {};
      }

      if (!offerStats.campaign_affiliates_custom[affiliateId]) {
        offerStats.campaign_affiliates_custom[affiliateId] = {};
      }

      if (isInvoiceIssued) {
        offerStats.campaign_affiliates_custom[affiliateId].invoice_issued = true;
        offerStats.campaign_affiliates_custom[affiliateId].invoice_issued_who = this.authService.getUserEmail();
        offerStats.campaign_affiliates_custom[affiliateId].invoice_issued_when = invoiceIssuedWhen;

        if (!affiliateCustom?.campaign_offers[campaignKey + "-" + offerId]) {

          if (!affiliateCustom.campaign_offers) {
            affiliateCustom.campaign_offers = {};
          }

          if (!affiliateCustom.campaign_offers[campaignKey + "-" + offerId]) {
            affiliateCustom.campaign_offers[campaignKey + "-" + offerId] = {};
          }
        }

        affiliateCustom.campaign_offers[campaignKey + "-" + offerId].invoice_issued = true;
        affiliateCustom.campaign_offers[campaignKey + "-" + offerId].invoice_issued_who = this.authService.getUserEmail();
        affiliateCustom.campaign_offers[campaignKey + "-" + offerId].invoice_issued_when = invoiceIssuedWhen;
      }

      if (isVisibleForaffiliate != null && isVisibleForaffiliate) {
        affiliateCustom.campaign_offers[campaignKey + "-" + offerId].is_visible_for_affiliate = isVisibleForaffiliate;
        offerStats.campaign_affiliates_custom[affiliateId].is_visible_for_affiliate = isVisibleForaffiliate;
      }

      if (!isInvoiceIssued) {
        if (typeof offerStats.campaign_affiliates_custom[affiliateId].invoice_issued !== 'undefined')
          delete offerStats.campaign_affiliates_custom[affiliateId].invoice_issued;
        if (typeof offerStats.campaign_affiliates_custom[affiliateId].invoice_issued_who !== 'undefined')
          delete offerStats.campaign_affiliates_custom[affiliateId].invoice_issued_who;
        if (typeof offerStats.campaign_affiliates_custom[affiliateId].invoice_issued_when !== 'undefined')
          delete offerStats.campaign_affiliates_custom[affiliateId].invoice_issued_when;

        if (typeof affiliateCustom.campaign_offers[campaignKey + "-" + offerId].invoice_issued !== 'undefined')
          delete affiliateCustom.campaign_offers[campaignKey + "-" + offerId].invoice_issued;
        if (typeof affiliateCustom.campaign_offers[campaignKey + "-" + offerId].invoice_issued_who !== 'undefined')
          delete affiliateCustom.campaign_offers[campaignKey + "-" + offerId].invoice_issued_who;
        if (typeof affiliateCustom.campaign_offers[campaignKey + "-" + offerId].invoice_issued_when !== 'undefined')
          delete affiliateCustom.campaign_offers[campaignKey + "-" + offerId].invoice_issued_when;
      }

      if (typeof isVisibleForaffiliate !== 'undefined' && isVisibleForaffiliate != null && !isVisibleForaffiliate) {
        if (offerStats.campaign_affiliates_custom[affiliateId].is_visible_for_affiliate)
          delete offerStats.campaign_affiliates_custom[affiliateId].is_visible_for_affiliate;

        if (typeof isVisibleForaffiliate !== 'undefined' && isVisibleForaffiliate != null && affiliateCustom.campaign_offers[campaignKey + "-" + offerId].is_visible_for_affiliate)
          delete affiliateCustom.campaign_offers[campaignKey + "-" + offerId].is_visible_for_affiliate;
      }
    } else {

      if (!affiliateCustom.campaign_offers) {
        affiliateCustom.campaign_offers = {};
      }

      if (!affiliateCustom.campaign_offers[campaignKey + "-" + offerId]) {
        affiliateCustom.campaign_offers[campaignKey + "-" + offerId] = {};
      }

      if (!affiliateCustom.campaign_offers[campaignKey + "-" + offerId].affiliates_custom_added) {
        affiliateCustom.campaign_offers[campaignKey + "-" + offerId].affiliates_custom_added = {};
      }

      if (!affiliateCustom.campaign_offers[campaignKey + "-" + offerId].affiliates_custom_added[affiliateId]) {
        affiliateCustom.campaign_offers[campaignKey + "-" + offerId].affiliates_custom_added[affiliateId] = {};
      }

      if (isInvoiceIssued) {
        offerStats.campaign_affiliates_custom_added[affiliateId].invoice_issued = true;
        offerStats.campaign_affiliates_custom_added[affiliateId].invoice_issued_who = this.authService.getUserEmail();
        offerStats.campaign_affiliates_custom_added[affiliateId].invoice_issued_when = invoiceIssuedWhen;

        affiliateCustom.campaign_offers[campaignKey + "-" + offerId].affiliates_custom_added[affiliateId].invoice_issued = true;
        affiliateCustom.campaign_offers[campaignKey + "-" + offerId].affiliates_custom_added[affiliateId].invoice_issued_who = this.authService.getUserEmail();
        affiliateCustom.campaign_offers[campaignKey + "-" + offerId].affiliates_custom_added[affiliateId].invoice_issued_when = invoiceIssuedWhen;
      }

      if (isVisibleForaffiliate != null && isVisibleForaffiliate) {
        affiliateCustom.campaign_offers[campaignKey + "-" + offerId].affiliates_custom_added[affiliateId].is_visible_for_affiliate = isVisibleForaffiliate;
        offerStats.campaign_affiliates_custom_added[affiliateId].is_visible_for_affiliate = isVisibleForaffiliate;
      }

       if (!isInvoiceIssued) {
        if (typeof offerStats.campaign_affiliates_custom_added[affiliateId].invoice_issued !== 'undefined')
          delete offerStats.campaign_affiliates_custom_added[affiliateId].invoice_issued;
        if (typeof offerStats.campaign_affiliates_custom_added[affiliateId].invoice_issued_who !== 'undefined')
          delete offerStats.campaign_affiliates_custom_added[affiliateId].invoice_issued_who;
        if (typeof offerStats.campaign_affiliates_custom_added[affiliateId].invoice_issued_when !== 'undefined')
          delete offerStats.campaign_affiliates_custom_added[affiliateId].invoice_issued_when;

        if (typeof affiliateCustom.campaign_offers[campaignKey + "-" + offerId].affiliates_custom_added[affiliateId].invoice_issued !== 'undefined')
          delete affiliateCustom.campaign_offers[campaignKey + "-" + offerId].affiliates_custom_added[affiliateId].invoice_issued;
        if (typeof affiliateCustom.campaign_offers[campaignKey + "-" + offerId].affiliates_custom_added[affiliateId].invoice_issued_who !== 'undefined')
          delete affiliateCustom.campaign_offers[campaignKey + "-" + offerId].affiliates_custom_added[affiliateId].invoice_issued_who;
        if (typeof affiliateCustom.campaign_offers[campaignKey + "-" + offerId].affiliates_custom_added[affiliateId].invoice_issued_when !== 'undefined')
          delete affiliateCustom.campaign_offers[campaignKey + "-" + offerId].affiliates_custom_added[affiliateId].invoice_issued_when;
      }

      if (typeof isVisibleForaffiliate !== 'undefined' && isVisibleForaffiliate != null && !isVisibleForaffiliate) {
        if (offerStats.campaign_affiliates_custom_added[affiliateId].is_visible_for_affiliate)
          delete offerStats.campaign_affiliates_custom_added[affiliateId].is_visible_for_affiliate;

        if (typeof isVisibleForaffiliate !== 'undefined' && isVisibleForaffiliate != null && affiliateCustom.campaign_offers[campaignKey + "-" + offerId].affiliates_custom_added[affiliateId].is_visible_for_affiliate)
          delete affiliateCustom.campaign_offers[campaignKey + "-" + offerId].affiliates_custom_added[affiliateId].is_visible_for_affiliate;
      }
    }

    await affiliateCustomRef.set(affiliateCustom);
    await this.db.collection("campaign_offer").doc(campaignKey + "-" + offerId).set(offerStats);
  }
}
