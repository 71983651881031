import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-import-conversion-data-dialog',
  templateUrl: './import-conversion-data-dialog.component.html',
  styleUrls: ['./import-conversion-data-dialog.component.css']
})
export class ImportConversionDataDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ImportConversionDataDialogComponent>) { }
  text;
  onClick(): void {
    this.dialogRef.close(this.text);
  }

  ngOnInit(): void {
  }

}
