import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-window',
  templateUrl: './confirmation-window.component.html',
  styleUrls: ['./confirmation-window.component.css']
})
export class ConfirmationWindowComponent implements OnInit {
  title:string;
  question_label:string;
  question:string;
  confirm_btn_text:string;
  cancel_btn_text:string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationWindowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      const { title, question, confirm_btn_text, cancel_btn_text, question_label } = data;
      this.title = title || 'Potwierdź';
      this.question = question || 'Jesteś pewien ?';
      this.question_label = question_label ;
      this.confirm_btn_text = confirm_btn_text || 'OK';
      this.cancel_btn_text = cancel_btn_text || 'Anuluj';
  }

  ngOnInit() { }

  Close() {
    this.dialogRef.close(false);
  }

  Confirm() {
    this.dialogRef.close(true);
  }
}