import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { User } from 'firebase';
import * as firebase from "firebase";
import { BreadcrumbService } from '../environment/breadcrumb.service';
import { LabelAdminService } from '../planner/shared/label-admin/label-admin.service';
import { Subscription } from 'rxjs';
import { FbFunctionsService } from '../environment/fb-functions.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { TokenAuthService } from '../environment/token-auth.service';

const wydawcaSecret = "wbiretA6B3HjaeKjjULp"

@Injectable()
export class AuthService {
  user: User;
  permissions: any;
  public redirectUrl: string;
  private affiliateRefId;
  private labelPermissionSubscription: Subscription;
  constructor(
    private angularFire: AngularFireAuth,
    private router: Router,
    private labelAdminService: LabelAdminService,
    private fbFunctionsService: FbFunctionsService,
    private db: AngularFirestore,
    private breadcrumb: BreadcrumbService,
    private tokenAuthService: TokenAuthService) {
    this.angularFire.authState.subscribe(user => {
      this.user = user;
      if (user) {
        if (this.labelPermissionSubscription)
          this.labelPermissionSubscription.unsubscribe();
        this.subscribeToLabelPermissions();
      }
    });
  }

  public isLoggedIn$ = this.angularFire.authState;

  subscribeToLabelPermissions() {
    this.labelPermissionSubscription = this.labelAdminService.getPermissions(this.user.email).subscribe(
      p => this.permissions = (p && p.permissions) ? p.permissions : null
    );
  }

  getAuth() {
    return this.angularFire;
  }

  getAffiliateUserId() {
    const token = sessionStorage.getItem('pae_token');
    if(!token || token == '') return null;
    const parsedToken = this.parseJwt(token);
    return parsedToken.is_g1? null : parsedToken.affiliate_id;
  }

  loginWithGoogle() {


    this.angularFire.signInWithPopup(new firebase.auth.GoogleAuthProvider())
    .catch(err => {
      this.breadcrumb.sendErrorMessage(err.message || err);
    })
      .then(async userCredential => {
        if (userCredential) {
          if (!userCredential.user.email.endsWith('@groupone.com.pl')) {
            this.breadcrumb.sendErrorMessage('Do systemu mogą zalogować się jedynie osoby z mailem w domenie groupone.com.pl');
            return await (await this.angularFire.currentUser).delete();
          }

          sessionStorage.removeItem('pae_token');

          const googleToken = await firebase.auth().currentUser.getIdToken();
          const token = await this.tokenAuthService.login(googleToken);
          sessionStorage.setItem('pae_token', token.body.data);

          if (!!(this.redirectUrl)) {
            this.router.navigate([this.redirectUrl]);
          } else {
            this.router.navigate(['/', 'planner']);
          }
        }
      });
  }

  login(email: string, password: string) {
    this.angularFire
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        this.angularFire.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

        if (this.redirectUrl && !(this.redirectUrl == '')) {
          this.router.navigate([this.redirectUrl]);
        } else {
          this.router.navigate(['/planner']);
        }
      })
      .catch(err => {
        console.log(err);
        this.breadcrumb.sendErrorMessage(err.message || err);
      });
  }

  signup(email: string, password: string) {
    this.angularFire.createUserWithEmailAndPassword(email, password)
      .then((resp: any) => {
        console.log('fire-signup-user', resp);
        if (resp.user && resp.user.email) {
          this.breadcrumb.sendInfoMessage(`Utworzono konto: ${resp.user.email}`);
        }
      }).catch(err => {
        console.log('error', err);
        this.breadcrumb.sendErrorMessage(err.message || err);
      });
  }

  async logout() {
    await this.angularFire.signOut()
      .then(value => {
        this.router.navigate(['/login'])
      });
    console.log('Logged out')
  }



  resetPasswordInit(email: string) {
    return this.angularFire.sendPasswordResetEmail(
      email,
      // { url: 'https://hasoffers-helper.firebaseapp.com/' }
    );
  }

  parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }

  public getUserEmail() {
    if (!(this.user)) { return ' niezalogowany '; }
    return this.user.email;
  }

  public loginOrCreateHoUser(email) {

    this.angularFire.createUserWithEmailAndPassword(email, wydawcaSecret)
      .then((resp: any) => {
        this.db.collection("users").doc(email).set({ roles: ["publisher"] }).then(
          callback => {
            console.log('fire-signup-user', resp);
            if (resp.user && resp.user.email) {
              this.breadcrumb.sendInfoMessage(`Utworzono konto: ${resp.user.email}`);
            }
          })
      })
      .catch(error => {
        switch (error.code) {
          case 'auth/email-already-in-use':
            break;
          default:
            this.breadcrumb.sendInfoMessage(`Błąd: ${error.message}`);
            break;
        }
      });

    this.login(email, wydawcaSecret);
  }

  public async loginAsHoUser(email, password) {
    try {
      const loginResp = await this.fbFunctionsService.getHoUser(email, password);

      // if (loginResp.body.ho_responses.response.status != 1 || loginResp.body.ho_responses.response.data.user_status != 'active') {
      //   this.breadcrumb.sendErrorMessage(`Błędne dane logowania Hasoffers`);
      //   return;
      // }

      // if (loginResp.body.ho_responses.response.data.user_type == "affiliate_user") {
      //   const affiliateId = loginResp.body.ho_responses.response.data.user_id;

      //   const affiliateResp = await this.fbFunctionsService.getAffiliateByUserId(affiliateId);

      //   if (affiliateResp.body.status != 'ok' || affiliateResp.body.ho_responses.response.status != 1 || affiliateResp.body.ho_responses.response.data.AffiliateUser.status != 'active') {
      //     this.breadcrumb.sendErrorMessage(`użytkownik nie ma przypiętego aktywnego kontrachenta`);
      //     return;
      //   }


      // this.affiliateRefId = affiliateResp.body.ho_responses.response.data.Affiliate.id;

      

      sessionStorage.setItem('pae_token', loginResp.body.data.token); //ustaw token

      this.loginOrCreateHoUser(email);
    this.router.navigate(['/publisher'])

  } catch(err) {
    this.breadcrumb.sendErrorMessage(err.message || err);
  }
}
}