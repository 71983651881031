import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./auth/login/login.component";
import { HomeComponent } from "./home/home.component";
import { AuthGuardGuard } from "./auth/auth-guard.guard";
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { ImportDataComponent } from "./import-data/import-data.component";
import { PublisherComponent } from "./publisher/publisher.component";
import { PublisherInvoiceInstructionComponent } from "./publisher/publisher-invoice-instruction/publisher-invoice-instruction.component";
import { RepozytoriumParentComponent } from "./repozytorium-parent/repozytorium-parent.component";
import { ImportDataConfigurationComponent } from "./import-data/import-data-configuration/import-data-configuration.component";
import { CreateImportDataConfigurationEntryComponent } from "./import-data/import-data-configuration/create-import-data-configuration/create-import-data-configuration.component";
import { EditImportDataConfigurationEntryComponent } from "./import-data/import-data-configuration/edit-import-data-configuration/edit-import-data-configuration.component";
import { PublisherArchiveComponent } from "./publisher-archive/publisher-archive.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "planner", pathMatch: "full",
    canActivate: [AuthGuardGuard]
  },
  { path: "login", component: LoginComponent },
  {
    path: "publisher",
    component: PublisherComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: "publisher-data-archive",
    component: PublisherArchiveComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: "publisher-invoice-instruction",
    component: PublisherInvoiceInstructionComponent,
    canActivate: [AuthGuardGuard]
  },
  { path: "change-password", component: ChangePasswordComponent },
  {
    path: "planner",
    loadChildren: "./planner/planner.module#PlannerModule",
    canActivate: [AuthGuardGuard]
  },
  {
    path: "offers",
    component: HomeComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: "import",
    component: ImportDataComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'import/konfiguracja',
    component: ImportDataConfigurationComponent,
    children: [
      {
        path: 'utworz',
        component: CreateImportDataConfigurationEntryComponent,
      },
      {
        path: ':id',
        component: EditImportDataConfigurationEntryComponent,
      }
    ]
  },
  {
    path: 'repozytorium/:token',
    component: RepozytoriumParentComponent,
    canActivate: [AuthGuardGuard]
  },
  { path: "**", redirectTo: "planner", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
