import { Injectable } from '@angular/core';

@Injectable()
export class NumberService {

  constructor() { }

  formatPLN = new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' });
  formatNum2d = new Intl.NumberFormat('pl-PL', { maximumFractionDigits: 2, useGrouping: false });
  formatPercent2d = new Intl.NumberFormat('pl-PL', { maximumFractionDigits: 2, style: 'percent'});
  
 convertToNumber = (input) =>{
    if(input)
    {
      return +(typeof input === "string" ? input.replace(',','.') : input );
    }
    return 0;
  }
}