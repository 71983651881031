import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import firebase, { firestore } from 'firebase';
import { BreadcrumbService } from 'src/app/environment/breadcrumb.service';
import { FbFunctionsService } from 'src/app/environment/fb-functions.service';
import { FileuploadService } from 'src/app/shared/fileupload.service';
import * as XLSX from 'xlsx';
import { NumberService } from '../../shared/number.service';

@Component({
  selector: 'app-create-validation-report',
  templateUrl: './create-validation-report.component.html',
  styleUrls: ['./create-validation-report.component.css']
})
export class CreateValidationReportComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('TABLE') table: ElementRef;

  displayedColumns: string[] = [
    'advertiser_info',
    'affiliate_name',
    'advertiser_name',
    'offer_id',
    'datetime',
    'sale_amount',
    'affiliate_info1',
    'status'
  ]

  text;
  dataSource;
  downloadingStats = false;
  validationReportName;
  campaign_offer;
  loading = true;
  advertisers;
  affiliateName;
  offerName;

  constructor(public dialogRef: MatDialogRef<CreateValidationReportComponent>, @Inject(MAT_DIALOG_DATA) private data: any, private firebaseFunctions: FbFunctionsService, private firestore: AngularFirestore, private numberService: NumberService) { }


  onClick(): void {
    this.dialogRef.close(this.text);
  }

  async ngOnInit() {
    this.dataSource = new MatTableDataSource([]);
    await this.refreshOfferConversions(this.data.offerId, this.data.startDate, this.data.endDate, this.data.affiliateId);
    const campaignOffers = (await this.firestore
      .collection("campaign_offer")
      .ref
      .where("campaign_offer.id", "==", this.data.offerId)
      .where("campaign.data_rozpoczecia", "==", this.data.startDate)
      .where("campaign.data_zakonczenia", "==", this.data.endDate).get())

    this.advertisers = ((await this.firestore.collection("ho_data").ref.doc("advertisers").get()).data()as any).data;

    this.campaign_offer = campaignOffers.docs[0].data()
    this.affiliateName = this.campaign_offer.campaign_affiliates[this.data.affiliateId].name;
    this.offerName = this.campaign_offer.campaign_offer.name;
    this.loading = false;

    this.initTable();
  }

  getAdvertiserName(advertiserId) {
    if(this.advertisers)
    return this.advertisers[advertiserId]?.company ?? "-";
  }

  formatDateComponent(text) {
    let processedText = text.toString();
    if (processedText.length == 1) {
      processedText = '0' + processedText;
    }

    return processedText;
  }

  async refreshOfferConversions(offerId, startDate, endDate, affiliateId) {
    if (!offerId) return;
    this.downloadingStats = true;
    const startDateWithHour = startDate + " 00:00:00";
    const endDateWithHour = endDate + " 23:59:59";
    const conversionsResponse = await this.firebaseFunctions.getOfferConversions(offerId, startDateWithHour, endDateWithHour);
    this.downloadingStats = false;
    const conversionsData = conversionsResponse.data.response.data.data;

    let conversionsDataList = Object.keys(conversionsData).map(k => conversionsData[k]);
    conversionsDataList = conversionsDataList.filter(c => c.Conversion?.affiliate_id == affiliateId);

    this.dataSource.data = conversionsDataList;
  }

  async generateAndUploadReport() {

  }

  initTable() {
    this.paginator._intl.itemsPerPageLabel = "rozmiar strony:";
    this.paginator._intl.firstPageLabel = "pierwsza strona";
    this.paginator._intl.lastPageLabel = "ostatnia strona";
    this.paginator._intl.previousPageLabel = "poprzednia strona";
    this.paginator._intl.nextPageLabel = "następna strona";
    this.paginator.pageSize = 5;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  exportParams() {
    return {
      fileName: `${this.offerName} - statystyki - ${(new Date()).toLocaleString('sv-SE')}`,
      sheet: 'statystyki',
      ignoreEC: true,
      compression: true,
    };
  }
  
  exportAsExcel() {
    const reportArray = this.dataSource.data.map(e => ({ 'Informacje od reklamodawcy': e.Conversion.advertiser_info, 'Id reklamodawcy': e.Conversion.affiliate_id, 'Id wydawcy': e.Conversion.advertiser_id, 'Id Oferty': e.Conversion.offer_id, 'Czas': e.Conversion.datetime, 'Wartość koszyka': e.Conversion.sale_amount, 'Wydawca Sub Id 1': e.Conversion.affiliate_info1, 'Status': e.Conversion.status }));
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(reportArray);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Raport');

    let wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

    function s2ab(s) {
      let buf = new ArrayBuffer(s.length)
      let view = new Uint8Array(buf)
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF
      return buf
    }

    let blob = new Blob([s2ab(wbout)], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
    });

    var file = new File([blob], "raport_walidacyjny.xlsx", { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,', lastModified: Date.now() });

    this.dialogRef.close(file);
  }

  applyFilter(szukaneSlowo: string) {
    this.dataSource.filter = szukaneSlowo.trim().toLocaleLowerCase();
  }

  async generateReport() {
    return this.exportAsExcel();
  }
}
