import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-repozytorium-parent',
  templateUrl: './repozytorium-parent.component.html',
  styleUrls: ['./repozytorium-parent.component.css']
})
export class RepozytoriumParentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
