import { Component, OnInit, Inject } from '@angular/core';
import { Form, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { BreadcrumbService } from 'src/app/environment/breadcrumb.service';
import { ImportDataConfiguration } from "src/app/models/ImportDataConfiguration";
import { ImportService } from '../../import.service';

@Component({ template: '' })
export class CreateImportDataConfigurationEntryComponent {
  offer_urls: any = {};
  affiliate_id = this.route.snapshot.params["affiliateId"];
  isCustomAdded = this.route.snapshot.params["isCustomAdded"] === 'custom';
  constructor(
    private breadcrumbs: BreadcrumbService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private importService: ImportService) {
    try {
      this.openCreateNewImportDataConfigurationDialog();
    }
    catch (err) {
      let message = err?.message;
      this.breadcrumbs.sendErrorMessage(message);
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  roundNumber(num, dec) {
    return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
  }

  public async openCreateNewImportDataConfigurationDialog() {

    let dialogData = {
      current_user: this.authService.getUserEmail()
    };

    const dialogRef = this.dialog.open(CreateImportDataConfigurationComponent, {
      width: '900px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(async (result: ImportDataConfiguration) => {
      if (result) {
        await this.importService.saveNewImportConfigurationToConfig(result);
      }
      this.router.navigate(['../'], { relativeTo: this.route });
    })
  }

}

@Component({
  selector: 'create-import-data-configuration',
  templateUrl: './create-import-data-configuration.component.html',
  styleUrls: ['./create-import-data-configuration.component.css']
})
export class CreateImportDataConfigurationComponent implements OnInit {
  userEmail: string;
  newImportDataConfigurationForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<CreateImportDataConfigurationEntryComponent>,
    public dialog: MatDialog,
    private breadcrumbsService: BreadcrumbService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.userEmail = data.current_user;

    this.newImportDataConfigurationForm = new FormGroup({
      name: new FormControl('', Validators.required),
      created_by: new FormControl(this.userEmail, Validators.required),
      created_when: new FormControl(new Date()),
      edited_by: new FormControl(this.userEmail, Validators.required),
      edited_when: new FormControl(new Date()),
      header_name_ord: new FormControl('', Validators.required),
      header_name_validation_status: new FormControl('', Validators.required),
      header_name_comment: new FormControl(''),
      header_name_transaction: new FormControl(''),
      validation_status_validated: new FormControl('', Validators.required),
      validation_status_waiting: new FormControl(''),
      validation_status_rejected: new FormControl('', Validators.required),
      comment: new FormControl(''),
    });
  }

  ngOnInit() { }


  Confirm() {
    if (this.newImportDataConfigurationForm.valid) {
      this.dialogRef.close(this.newImportDataConfigurationForm.value);
    } else {
      this.breadcrumbsService.sendErrorMessage("Przed utworzeniem nowej konfiguracji należy wypełnić wszystkie wymagane pola");
    }
  }

  Close(): void {
    this.dialogRef.close();
  }
}