import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class FbFunctionsService {
  token: string = 'v1';
  baseUrl = environment.affiliateExpertFunctionsBasePath;
  
  constructor(private http: HttpClient) { }

  public resetApprovedAffiliates4Offer(offer_id: number) {
    const url = this.baseUrl + `hoapi/refresh_offer_ho_data/${offer_id}/${this.token}`;
    const resp = this.http.get(url);
    return resp;
  }

  public resetSokCampaigns(rok: number): Promise<any> {
    const url = this.baseUrl + `sokapi/reset_sok_campaigns/${this.token}/${rok}`;
    return this.http.get(url).toPromise();
  }

  public getSokClients(): Promise<any> {
    const url = this.baseUrl + `sokapi/get_sok_clients/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public getSokSuppliers(): Promise<any> {
    const url = this.baseUrl + `sokapi/get_sok_suppliers/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public getSokSupplierPlacements(id_wydawcy: string): Promise<any> {
    const url = this.baseUrl + `sokapi/get_sok_supplier_placements/${id_wydawcy}/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public getAdvertiserOffers(id_reklamodawcy: number): Promise<any> {
    const url = this.baseUrl + `hoapi/get_advertiser_offers/${id_reklamodawcy}/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public getAdvertisers(): Promise<any> {
    const url = this.baseUrl + `hoapi/get_advertisers/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public getAffiliates(): Promise<any> {
    const url = this.baseUrl + `hoapi/get_affiliates/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public setAdvertiserRefId(id_reklamodawcy: string, ref_id: string): Promise<any> {
    const url = this.baseUrl + `hoapi/set_advertiser_attr/${id_reklamodawcy}/ref_id/${ref_id}/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public setAffiliateRefId(id_wydawcy: string, ref_id: string): Promise<any> {
    const url = this.baseUrl + `hoapi/set_affiliate_attr/${id_wydawcy}/ref_id/${ref_id}/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public get_offer_approved_affiliates(offer_id: string): Promise<any> {
    const url = this.baseUrl + `hoapi/get_offer_approved_affiliates/${offer_id}/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public get_offer_affiliate_payment(offer_id: string, affiliate_id: string): Promise<any> {
    const url = this.baseUrl + `hoapi/get_offer_affiliate_payment/${offer_id}/${affiliate_id}/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public get_offer_urls(offer_id: string): Promise<any> {
    const url = this.baseUrl + `hoapi/get_offer_urls/${offer_id}/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public get_offer_stats(offer_id: string, period_start: string, period_end: string): Promise<any> {
    const url = this.baseUrl + `hoapi/get_offer_period_stats/${offer_id}/${period_start}/${period_end}/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public getClientCampaigns(clientId: string, year: string): Promise<any> {
    const url = this.baseUrl + `fsdb/get_client_campaigns/${clientId}/${year}/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public importOffersFromAnotherCampaign(campaignToImportFrom: string, campaignToImportTo: string, selectedOfferIds, email: string): Promise<any> {

    const url = this.baseUrl + `fsdb/import_offers_from_another_campaign/${campaignToImportFrom}/${campaignToImportTo}/${selectedOfferIds.join(';')}/${email}/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public refreshCampaignOfferStats(campaignKey: string, offerIds: any, startDate: string, endDate: string): Promise<any> {
    const url = this.baseUrl + `hoapi/refresh_campaign_offers_stats/${campaignKey}/${offerIds.join(';')}/${startDate}/${endDate}/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public getOfferConversions(offerId: number, startDate: string, endDate: string): Promise<any> {
    const url = this.baseUrl + `hoapi/get_offer_conversions/${offerId}/${startDate}/${endDate}/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public updateAdvertisersInImportConfig(): Promise<any> {
    const url = this.baseUrl + `hoapi/update_advertisers_in_import_config/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public updateOffersInImportConfig(advertiserId: number): Promise<any> {
    const url = this.baseUrl + `hoapi/update_offers_in_import_config/${advertiserId}/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public updateAffiliatesInImportConfig(): Promise<any> {
    const url = this.baseUrl + `hoapi/update_affiliates_in_import_config/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public updateConversionsInHasoffers(conversions: string): Promise<any> {
    const url = this.baseUrl + `hoapi/update_conversions_in_hasoffers/${conversions}/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public createConversionsInHasoffers(conversions: string, offerId: string, datetime: string): Promise<any> {
    const url = this.baseUrl + `hoapi/create_conversions_in_hasoffers/${offerId}/${datetime}/${conversions}/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public getPublisher(publisherEmail: string): Promise<any> {
    const url = this.baseUrl + `hoapi/get_publisher/${publisherEmail}/${this.token}`;
    return this.http.get(url).toPromise();
  }

  public getHoUser(login: string, password: string): Promise<any> {
    const url = this.baseUrl + `hoapi/get_ho_user/${this.token}`;

    return this.http.post(url, { login, password },
      {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
        observe: 'response'
      }).toPromise();
  }

  public getAffiliateByUserId(user_id: string): Promise<any> {
    const url = this.baseUrl + `hoapi/get_affiliate_by_user_id/${this.token}`;

    return this.http.post(url, { user_id },
      {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
        observe: 'response'
      }).toPromise();
  }
  
}