import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-publisher-invoice-instruction',
  templateUrl: './publisher-invoice-instruction.component.html',
  styleUrls: ['./publisher-invoice-instruction.component.css']
})
export class PublisherInvoiceInstructionComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  openPublisherPanel() {
    this.router.navigate(['/publisher'])
  }

}
