import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ImportConfiguration } from "src/app/models/ImportConfiguration";
import { ImportService } from '../import.service';

@Component({
  selector: 'app-import-data-configuration',
  templateUrl: './import-data-configuration.component.html',
  styleUrls: ['./import-data-configuration.component.css']
})
export class ImportDataConfigurationComponent implements OnInit {

  configurationsList: any[];
  configurationsSubscription: Subscription;
  dataSource: MatTableDataSource<any>;
  config: ImportConfiguration;

  displayedColumns: string[] = [
    'name',
    'header_name_ord',
    'header_name_validation_status',
    'header_name_comment',
    'header_name_transaction',
    'validation_status_validated',
    'validation_status_waiting',
    'validation_status_rejected',
    'edited_by',
    'edited_when',
    'comment'
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService, private importService: ImportService) {
    this.dataSource = new MatTableDataSource([]);
  }

  checkUserPermissions = (actionName: string) => this.authService.permissions ? this.authService.permissions.includes(actionName) : false;

  ngOnInit() {
    this.configurationsSubscription = this.importService.getConfig$().subscribe(snap => {

      if (!snap.payload.exists) return;
      this.config = snap.payload.data();

      this.dataSource.data = this.config.import_configurations ? Object.keys(this.config.import_configurations).map(k => ({ id: k, ...this.config.import_configurations[k] })) : [];
    });
  }

  getFormattedDateWithHour(date: any) {
    if (!date) return;
    return new Date(date.seconds ? date.seconds * 1000 : date).toLocaleDateString('pl-PL', { hour: '2-digit', minute: '2-digit' });
  }

  initTable() {
    this.paginator._intl.itemsPerPageLabel = "rozmiar strony:";
    this.paginator._intl.firstPageLabel = "pierwsza strona";
    this.paginator._intl.lastPageLabel = "ostatnia strona";
    this.paginator._intl.previousPageLabel = "poprzednia strona";
    this.paginator._intl.nextPageLabel = "następna strona";
    this.paginator.pageSize = 20;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  ngOnDestroy() {
    this.configurationsSubscription.unsubscribe();
  }

  openImportConfigurationEditDialog(rowData) {

    const navigationExtras: NavigationExtras = {
      state: rowData,
      relativeTo: this.route
    };
    this.router.navigate([rowData.id], navigationExtras);
  }

  createNewImportDataConfiguration() {
    this.router.navigate(['utworz'], { relativeTo: this.route });
  }

  applyFilter(searchedKeyword: string) {
    this.dataSource.filter = searchedKeyword.trim().toLocaleLowerCase();
  }
}
