import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AppComponent, SnackBarComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthService } from './auth/auth.service';
import { AuthGuardGuard } from './auth/auth-guard.guard';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NaviBarComponent } from './navi-bar/navi-bar.component';
import { EnvironmentModule } from './environment/environment.module';
import { NewCampaignComponent } from './shared/new-campaign/new-campaign.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { APP_BASE_HREF } from '@angular/common';
import { ImportDataComponent } from './import-data/import-data.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ImportConversionDataDialogComponent } from './import-data/import-conversion-data-dialog/import-conversion-data-dialog.component';
import { PublisherComponent } from './publisher/publisher.component';
import { ChartsModule } from 'ng2-charts';
import { PublisherInvoiceInstructionComponent } from './publisher/publisher-invoice-instruction/publisher-invoice-instruction.component';
import { NumberService } from './planner/shared/number.service';
import { CreateValidationReportComponent } from './planner/camp-stats-edit/create-validation-report/create-validation-report.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ConfirmDialogComponent } from './repozytorium/confirm-dialog/confirm-dialog.component';
import { RepozytoriumComponent } from './repozytorium/repozytorium.component';
import { environment } from 'src/environments/environment';
import { RepozytoriumService } from './repozytorium/repozytorium.service';
import { RepozytoriumParentComponent } from './repozytorium-parent/repozytorium-parent.component';
import { ImportDataConfigurationComponent } from './import-data/import-data-configuration/import-data-configuration.component';
import { EditImportDataConfigurationComponent, EditImportDataConfigurationEntryComponent } from './import-data/import-data-configuration/edit-import-data-configuration/edit-import-data-configuration.component';
import { CreateImportDataConfigurationComponent, CreateImportDataConfigurationEntryComponent } from './import-data/import-data-configuration/create-import-data-configuration/create-import-data-configuration.component';
import { CampaignsService } from './planner/campaigns.service';
import { ImportSendDialogComponent } from './import-data/import-send-dialog/import-send-dialog.component';
import { HttpConfigInterceptor } from './interceptors/httpconfig.interceptor';
import { PublisherArchiveComponent } from './publisher-archive/publisher-archive.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AppRoutingModule,
    HttpClientModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatDialogModule,
    MatTabsModule,
    MatChipsModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    BrowserModule,
    HttpClientModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatListModule,
    MatButtonToggleModule,
    MatTableExporterModule,
    ChartsModule,
    MatCardModule,
    MatSlideToggleModule,
    EnvironmentModule.forRoot()
  ],
  declarations: [AppComponent, SnackBarComponent, HomeComponent, LoginComponent, NaviBarComponent, NewCampaignComponent, ChangePasswordComponent, ImportDataComponent, 
    CreateValidationReportComponent, ImportConversionDataDialogComponent, PublisherComponent, PublisherInvoiceInstructionComponent, RepozytoriumComponent, 
    ConfirmDialogComponent, RepozytoriumParentComponent, ImportDataConfigurationComponent, EditImportDataConfigurationEntryComponent, 
    CreateImportDataConfigurationEntryComponent, EditImportDataConfigurationComponent, CreateImportDataConfigurationComponent, ImportSendDialogComponent, PublisherArchiveComponent],
  entryComponents: [NewCampaignComponent, SnackBarComponent, CreateValidationReportComponent, ImportConversionDataDialogComponent, ConfirmDialogComponent,
     CreateImportDataConfigurationComponent, CreateImportDataConfigurationEntryComponent, EditImportDataConfigurationComponent, EditImportDataConfigurationEntryComponent, ImportSendDialogComponent],
  bootstrap: [AppComponent],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' }, AuthService, AuthGuardGuard, NumberService, RepozytoriumService, CampaignsService, { provide: 'Window', useValue: window },    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },

  ]
})
export class AppModule { }
