import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RepozytoriumService {
  BASE_PATH = "https://us-central1-repozytorium-g1.cloudfunctions.net/repository_api";

  constructor(private http: HttpClient) { }

  isNotEmpty(value) {
    return value !== undefined && value !== null && value !== "";
  }

  async getRepositoryData(token, requestedPath = "") {
    if (!token) {
      throw new Error("Brak tokenu do repozytorium")
    }

    const endpoint = this.BASE_PATH + "/get_folder";
    let functionUrl = this.getRepositoryPath(requestedPath, endpoint);
    const folderContent = await this.getData(functionUrl, token);

    if (!folderContent.structure) {
      throw new Error("Token nie zawiera konfiguracji dla klienta");
    }
    
    let structure = folderContent.structure;

    let itemList = Object.keys(structure).map(k => structure[k]);

    for (let i = 0; i < itemList.length; i++) {
      var t = new Date(1970, 0, 1);
      t.setSeconds(itemList[i].create_datetime._seconds);

      itemList[i].create_datetime = t;
    }

    return itemList;
  }

  async getData(functionUrl, repositoryToken): Promise<any> {
    const result = await this.http.get(functionUrl, {
      headers: {
        'Authorization': `Bearer ${repositoryToken}`,
      },
    }).toPromise();

    return result;
  }

  getRepositoryPath(requestedPath, endpoint) {
    if (requestedPath != "") {
      if (requestedPath.startsWith('/')) {
        requestedPath = requestedPath.substring(1);
      }
      return endpoint + "/" + encodeURIComponent(requestedPath.toString());
    } else {
      return endpoint;
    }
  }
}