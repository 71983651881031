// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   affiliateExpertFunctionsBasePath: "https://us-central1-hasoffers-helper.cloudfunctions.net/",
//   repositoryToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3N1ZXIiOiJwcnplbXlzbGF3Lmd3YXJla0Bncm91cG9uZS5jb20ucGwiLCJpdGVtX29wZXJhdGlvbnMiOlsiYWxsIl0sInByb2plY3RfbmFtZSI6IlJlcG96eXRvcml1bSIsInJvb3RfcGF0aCI6ImFmZmlsaWF0ZV9leHBlcnQvKiIsImN1c3RvbSI6IntcInJlcG9zaXRvcnlfY2xpZW50XCI6e1wiaXRlbV9hY3Rpb25zXCI6W3tcImZcIjpcInJlc3RvcmVfdmVyc2lvblwiLFwibFwiOlwiUHJ6eXdyb2NcIn1dLFwiZ2xvYmFsX2FjdGlvbnNcIjpbe1wiZlwiOlwiY3JlYXRlX2RhdGFiYXNlX3NuYXBzaG90XCIsXCJsXCI6XCJVdHdvcnoga29waWUgYmF6eVwifV19fSIsInZhbGlkX3RvIjoiMjAzMS0wMS0wNFQwNToyNDo1My4yNDlaIiwiZG93bmxvYWRfdXJsX2V4cGlyYXRpb25fdGltZSI6NDgwLCJ0b2tlbl9pZCI6MjgsImlhdCI6MTYyMTIyNTU5Mn0.aVFnclUnCx2xa2SVkv-HOxoQEfSvlOOoKe0zgfzZie4",
//   firebaseConfig: {
//     apiKey: 'AIzaSyD28D2XxEaIfLW_qLJd6jMpc9oE9BKahIE',
//     authDomain: 'hasoffers-helper.firebaseapp.com',
//     databaseURL: 'https://hasoffers-helper.firebaseio.com',
//     projectId: 'hasoffers-helper',
//     storageBucket: 'hasoffers-helper.appspot.com',
//     messagingSenderId: '498888814625',
//     appId: '1:498888814625:web:2c2698f19af98fe456f360',
//     measurementId: 'G-72HKJ3PJWZ'
//   }
// };
export const environment = {
  production: true,
  affiliateExpertFunctionsBasePath: "https://us-central1-aff-salestube.cloudfunctions.net/",
  // affiliateExpertFunctionsBasePath: "http://localhost:5001/aff-salestube/us-central1/",
  affiliateExpertArchiveDataBasePath: "https://affiliateexpert-api.groupone.pl/",
  //affiliateExpertArchiveDataBasePath: "https://localhost:44377/",
  tokenRefreshBeforeExpirationInMinutes: 10,
  repositoryToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3N1ZXIiOiJwcnplbXlzbGF3Lmd3YXJla0Bncm91cG9uZS5jb20ucGwiLCJpdGVtX29wZXJhdGlvbnMiOlsiYWxsIl0sInByb2plY3RfbmFtZSI6IlJlcG96eXRvcml1bSIsInJvb3RfcGF0aCI6ImFmZmlsaWF0ZV9leHBlcnQvKiIsImN1c3RvbSI6IntcInJlcG9zaXRvcnlfY2xpZW50XCI6e1wiaXRlbV9hY3Rpb25zXCI6W3tcImZcIjpcInJlc3RvcmVfdmVyc2lvblwiLFwibFwiOlwiUHJ6eXdyb2NcIn1dLFwiZ2xvYmFsX2FjdGlvbnNcIjpbe1wiZlwiOlwiY3JlYXRlX2RhdGFiYXNlX3NuYXBzaG90XCIsXCJsXCI6XCJVdHdvcnoga29waWUgYmF6eVwifV19fSIsInZhbGlkX3RvIjoiMjAzMS0wMS0wNFQwNToyNDo1My4yNDlaIiwiZG93bmxvYWRfdXJsX2V4cGlyYXRpb25fdGltZSI6NDgwLCJ0b2tlbl9pZCI6MjgsImlhdCI6MTYyMTIyNTU5Mn0.aVFnclUnCx2xa2SVkv-HOxoQEfSvlOOoKe0zgfzZie4",
  firebaseConfig: {
      apiKey: "AIzaSyCto2_xhyNWk49Jrm6Zee2kEZRmjMD1L4o",
      authDomain: "aff-salestube.firebaseapp.com",
      databaseURL: "https://aff-salestube.firebaseio.com",
      projectId: "aff-salestube",
      storageBucket: "aff-salestube.appspot.com",
      messagingSenderId: "980687076022",
      appId: "1:980687076022:web:c8c0bc6aec87a33a794417"
    }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
