import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-import-send-dialog',
  templateUrl: './import-send-dialog.component.html',
  styleUrls: ['./import-send-dialog.component.css']
})
export class ImportSendDialogComponent implements OnInit {

  conversionsToUploadAmount;
  conversionsToAddAmount;
  offersToAddStTo;
  duplicates;

  constructor(public dialogRef: MatDialogRef<ImportSendDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.conversionsToUploadAmount = data.conversionsToUploadAmount;
      this.duplicates = data.conversionsToUploadDuplicatesAmount;
      this.conversionsToAddAmount = data.conversionsToAddAmount;
      this.offersToAddStTo = data.offersToAddStTo.map(c => c.campaign.key).join(',');
    }

  onSendClick(): void {
    this.dialogRef.close(true);
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  ngOnInit(): void {
  }

}
