import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { BreadcrumbService } from './environment/breadcrumb.service';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';

const productionDomains = ['aff-salestube.firebaseapp.com', 'affiliateexpert.groupone.pl'];

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Hasoffers-helper';
  shouldDisplayG1Menu = false;
  isLoggedInSubscription: Subscription;

  checkForShouldDisplayMenu () {
    this.isLoggedInSubscription = this.authService.isLoggedIn$.subscribe(user => {
      this.shouldDisplayG1Menu = user?.email.endsWith('@groupone.com.pl');
    })
  };

  constructor(public authService: AuthService, private router: Router, private db: AngularFirestore,
    private breadcrumbs: BreadcrumbService, private _snackBar: MatSnackBar, @Inject('Window') private window: Window) {
      
  }

  wyloguj() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  async ngOnInit() {
    this.breadcrumbs.infoMessage.subscribe(msg => this.showSnackMessage(msg, '', 'info'));
    this.breadcrumbs.warningMessage.subscribe(msg => this.showSnackMessage(msg, '', 'warning'));
    this.breadcrumbs.errorMessage.subscribe(msg => this.showSnackMessage(msg, 'Błąd', 'error'));
    const domain = window.location.hostname;
    if (productionDomains.includes(domain)) {
      this.applyProductionTheming();
    }

    this.checkForShouldDisplayMenu();
  }
  ngOnDestroy() {
    this.isLoggedInSubscription.unsubscribe();
  }

  showSnackMessage(message: string, action: string = 'Informacja', type: string = 'info') {
    if (!message) return;
    this._snackBar.openFromComponent(SnackBarComponent, {
      duration: 3000,
      data: { message, action },
      panelClass: [type]
    });
  }

  applyProductionTheming() {
    var headID = document.getElementsByTagName('head')[0];
    var link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.id = 'widget_styles';
    headID.appendChild(link);

    link.href = './assets/styles/salestube-styles.css';
  }
}

@Component({
  selector: 'snack-bar-message',
  template: `
  <span class='example-pizza-party'>
    {{data.action ? (data.action + ':') : ''}} {{data.message}}
  </span>  
  `,
  styles: [
    `::ng-deep  .info { background-color: green  !important; }`,
    `::ng-deep  .success { background-color: green  !important; }`,
    `::ng-deep  .ok { background-color: green  !important; }`,
    `::ng-deep  .error { background-color: red  !important; }`,
    `::ng-deep  .warning { background-color: #ffcc00  !important; color: black}`
  ]
})
export class SnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
};