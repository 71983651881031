import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Subscription, fromEventPattern } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatButton } from '@angular/material/button';
import { PageEvent } from '@angular/material/paginator';
import { FsdbService } from '../environment/fsdb.service';
//import { FsdbService } from '@env/fsdb.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  title = 'Home Hasoffers-helper';
  onlyActiveOffers: boolean = true;
  menuItemsSub: Subscription;
  menu_items:any[] = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['advertiser_name', 'offer_name', 'offer_id', 'offer_status', 'last_updated'];
  pageEvent: PageEvent;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  // constructor(private authService: AuthService, private dbs: DbService) {   
  constructor(private authService: AuthService, private dbs: FsdbService) {       
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit() {    
    this.initTable();
    this.menuItemsSub = this.dbs.menuItemsSub
    .subscribe(items => {
      const advertisers = Object.keys(items).map(k => items[k]);
      this.menu_items = [];
      const last_updated = this.dbs.menu_items.last_updated && new Date(this.dbs.menu_items.last_updated.seconds * 1000).toLocaleString();
      advertisers.forEach(a => 
        Object.keys(a.offers).map(k => a.offers[k]).forEach(o => {
          this.menu_items.push({
            advertiser_id: a.id,
            advertiser_name: a.name,
            advertiser_name_lc: a.name.toLowerCase(),
            advertiser_status: a.status,
            offer_id: o.id,
            offer_name: o.name,
            offer_name_lc: o.name.toLowerCase(),
            offer_status: o.status, 
            last_updated: last_updated
          });
        })
      );
      this.refreshItems();
    });
  }

  refreshItems() {
      this.dataSource.data = this.menu_items.filter(
        o => (!this.onlyActiveOffers || o.offer_status == 'active')
      );
  }

  private initTable() {
    this.paginator._intl.itemsPerPageLabel = "rozmiar strony:";
    this.paginator._intl.firstPageLabel = "pierwsza strona";
    this.paginator._intl.lastPageLabel = "ostatnia strona";
    this.paginator._intl.previousPageLabel = "poprzednia strona";
    this.paginator._intl.nextPageLabel = "następna strona";
    this.paginator.pageSize = 10;
    this.dataSource.paginator = this.paginator;   
    this.dataSource.sort = this.sort;     
    this.dataSource.filterPredicate = (data:any, filterWord:string) => {
      const word = filterWord.toLowerCase();
      if (data.offer_status.indexOf(word) >= 0) return true;
      if (data.offer_name_lc.indexOf(word) >= 0) return true;
      if (data.offer_id.indexOf(word) >= 0) return true;
      if (data.advertiser_name_lc.indexOf(word) >= 0) return true;
      return false;
    };
  }
  onlyActiveOffersChanged(ch) {
    this.onlyActiveOffers = ch.checked;
    this.refreshItems();
  }
  ngOnDestroy() {
    if(this.menuItemsSub)
      this.menuItemsSub.unsubscribe();
  }

  applyFilter(szukaneSlowo: string) {
    this.dataSource.filter = szukaneSlowo.trim().toLocaleLowerCase();
  }

}