import { Injectable } from '@angular/core';
import { CanActivateChild, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuardGuard implements CanActivate, CanActivateChild {
  info: string;
  infoMessage = new BehaviorSubject('');
  currentMessage = this.infoMessage.asObservable();

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>{
    let url: string = state.url;
    return this.checkLogin(url);
  }

   checkLogin(url: string):Observable<boolean>{
    return this.authService.isLoggedIn$.pipe(map(
      callback => {
        if(callback) return true;
        this.authService.redirectUrl = url;

        this.router.navigate(['/login']);
        return false;
      }
    ));
  }

  constructor(private authService: AuthService, private router: Router) { }

 canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      return this.canActivate(childRoute, state);
  }
}
