import { Component, OnInit, OnDestroy  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { BreadcrumbService } from '../../environment/breadcrumb.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  oldPassword:string;
  newPassword:string;
  confirmPassword:string;
  email: string;
  ngUnsubscribe: Subject<void> = new Subject<void>(); 
  mode:string = 'initResetPassword';
  actionCode:string;
  actionCodeChecked = false;

  constructor(
    private authService: AuthService,
    private breadcrumb: BreadcrumbService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    ) { 
      this.email = this.authService.getUserEmail();      
      // this.router.navigate([ '../' ], { relativeTo: this.route });
    }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {        
        this.actionCode = params['oobCode'];

        if (params['mode']) {
          this.mode = params['mode'];
          switch (params['mode']) {
            case 'resetPassword': {
              this.authService.getAuth().verifyPasswordResetCode(this.actionCode).then(email => {
                this.actionCodeChecked = true;
              }).catch(e => {
                console.log('verifyPasswordResetCode', e)
                this.breadcrumb.sendErrorMessage(e.message || e);
              });
            } break;
            case 'recoverEmail': {} break;
            case 'verifyEmail': {} break
            default: {}
          }
        }

      });    
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  resetPassword(event) { 
    event.target.style.display = 'none';
    if (!this.email) { 
      this.breadcrumb.sendErrorMessage('Nierozpoznany zalogowany użytkownik');
    }
    this.authService.resetPasswordInit(this.email) 
    .then(
      () => {
        this.breadcrumb.sendInfoMessage('Link do ustalenia nowego hasła został wysłany na adres e-mail.');
        event.target.style.display = 'block';
      }, 
      (rejectionReason) => alert(rejectionReason)) 
    .catch(e => {
      console.error(e);
      this.breadcrumb.sendErrorMessage('Wystąpił problem podczas próby ustalania nowego hasła: ' + (e.message || e));
      event.target.style.display = 'block';
    }); 
  }

  handleResetPassword() { 
    if (this.newPassword != this.confirmPassword) { 
      this.breadcrumb.sendErrorMessage('Wpisane hasła nie pasują do siebie!'); 
      return; 
    } 
    
    this.authService.getAuth().confirmPasswordReset(
      this.actionCode, 
      this.newPassword
    ).then(resp => { 
      this.breadcrumb.sendInfoMessage('Nowe hasło zostało zapisane');
      this.router.navigate(['/']); 
    }).catch(e => { 
      console.log('confirmPasswordReset: ', e);
    }); 
  }  

}