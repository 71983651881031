import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent as ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CustomFunctionsService {

  constructor(private _http: HttpClient, private _snackBar: MatSnackBar, public dialog: MatDialog) { }

  public invokeFunctionByName(methodName, param) {
    if (this[methodName]) {
      if (param) {
        
        this[methodName]([param]);
      } else {
        this[methodName]();
      }
    }
  }

  async openDialog(title, dialogMessage): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { dialogMessage, title, showMessage: true, showSpinner: false, doCancel: true }
    });

    const result = await dialogRef.afterClosed().toPromise();
    return result;
  };

  openSpinnerDialog() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { title: "Wykonywanie...", showMessage: false, showSpinner: true, doCancel: false }
    });
    return dialogRef;
  }

  /***********CUSTOM FUNCTIONS*************** */

  private restore_version(relativePath) {

    this._snackBar.open('Przywrócono zrzut bazy', "Ok", {
      duration: 3000,
      panelClass: ['success-snackbar']
    });

return;



    const name = "Przywróć zrzut bazy"
    const dialogMessage = "Czy na pewno chcesz przywrócić zrzut bazy? Obecne dane zostaną bezpowrotnie utracone.";
    this.openDialog(name, dialogMessage).then(result => {
      if (!result) return;
      const spinnerDialogRef = this.openSpinnerDialog();
      try {
        if (!relativePath || relativePath === "") {
          throw new Error("Ścieżka do zrzutu bazy w repozytorium jest nieprawidłowa.");
        }

        const encodedRelativePath = encodeURIComponent(relativePath)
        const functionUrl = environment.affiliateExpertFunctionsBasePath + "repoapi/import_data/" + encodedRelativePath;
        this._http.get(functionUrl).toPromise().then(a => {
          spinnerDialogRef.close();
          this._snackBar.open('Przywrócono zrzut bazy', "Ok", {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
          console.log("Przywrócono snapshot.")
        });
      } catch (e) {
        spinnerDialogRef.close();
        this._snackBar.open('Błąd: ' + e.message || e, "Ok", {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    });

  }

  private create_database_snapshot() {

    const name = "Utwórz nowy zrzut bazy"
    const dialogMessage = "Czy na pewno chcesz wykonać zrzut bazy firestore i zapisać ją w repozytorium?";
    this.openDialog(name, dialogMessage).then(result => {
      if (!result) return;
      const spinnerDialogRef = this.openSpinnerDialog();
      try {
        const functionUrl = environment.affiliateExpertFunctionsBasePath + "repoapi/export_data"
        this._http.get(functionUrl).toPromise().then(a => {
          spinnerDialogRef.close()
          this._snackBar.open('Wykonano kopię bazy i zapisano w repozytorium', "Ok", {
            duration: 3000
          });
          console.log("Wykonano snapshot.")
        });
      } catch (e) {
        spinnerDialogRef.close();
        this._snackBar.open('Błąd: ' + e.message || e, "Ok", {
          duration: 3000
        });
      }
    });

  }
}