import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Action, AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth/auth.service';
import { FbFunctionsService } from '../environment/fb-functions.service';
import { ConfirmationWindowComponent } from './shared/confirmation-window/confirmation-window.component';
import { ICampaignInfo } from "../models/ICampaignInfo";
import { ICampaignOffer } from "../models/ICampaignOffer";
import { ICampaignAffiliate } from "../models/ICampaignAffiliate";
import { ICampaignAttributes } from "../models/ICampaignAttributes";
import { ICampaign } from "../models/ICampaign";

@Injectable()
export class CampaignsService {
  public aktywny_rok: number = (new Date()).getFullYear();
  public AktywnyRokSub = new BehaviorSubject<number>(this.aktywny_rok);
  public dodawanie_wydawcy_do_kampanii: boolean = false;
  public dodawanie_offer_url_do_kampanii: boolean = false;

  public campaignsSokCollectionName = "campaigns";
  public campaignsAttributesCollectionName = "campaign_attributes";
  private campaignsSokRef = this.db.collection(this.campaignsSokCollectionName);
  private campaignAttributesRef = this.db.collection(this.campaignsAttributesCollectionName);

  year_campaigns: any = {};

  constructor(
    private db: AngularFirestore,
    private authService: AuthService,
    private func: FbFunctionsService,
    private dialog: MatDialog) {
    this.aktywny_rok = (new Date()).getFullYear();
  }

  public getCampaignsRef = this.campaignsSokRef;

  public DostepneLataKampanii() {
    const lata: any[] = [], today = new Date();
    let rok: number = 2018;
    for (rok; rok <= today.getFullYear(); rok++) lata.push({ rok });
    if (today.getMonth() >= 6) lata.push({ rok })
    return lata;
  }

  public setActiveYear(rok: number) {
    this.aktywny_rok = rok;
    this.AktywnyRokSub.next(this.aktywny_rok);
  }

  public async getUserConfirmation(confParams: any = {}) {
    const dialogRef = this.dialog.open(ConfirmationWindowComponent, {
      width: '600px',
      data: confParams
    });

    return await dialogRef.afterClosed().toPromise();
  }

  public async getCampaigns() {
    try {

      if (!this.year_campaigns[this.aktywny_rok]) {
        const camps_req = (await this.db.collection('campaigns').doc(`${this.aktywny_rok}`).get().toPromise());
        const campaigns_data: any = camps_req.data();

        this.year_campaigns[this.aktywny_rok] =
          campaigns_data.campaigns;

      }
      return this.year_campaigns[this.aktywny_rok];
    }
    catch (err) {
      console.error('getCampaigns', err.message || err);
      return [];
    }
  }

  public getCampaignsObservable: Observable<any> = this.db.collection('campaigns').doc(`${this.aktywny_rok}`).valueChanges() as Observable<any>;


  public async setCampaignComments(campaign_key: string, comments: string) {
    try {
      let campaign: any = {};
      const campaign_data = (await this.campaignAttributesRef.doc(campaign_key).get().toPromise());
      if (campaign_data.exists) {
        campaign = campaign_data.data();
        campaign.labels = campaign.labels || {};
        campaign.offers = campaign.offers || {};
        campaign.comments = comments;
      }
      else {
        campaign = {
          comments: comments,
          labels: {},
          offers: {}
        };
      }

      await this.campaignAttributesRef.doc(campaign_key).set(campaign);
      return {
        status: 'success',
        message: 'zmieniono komentarz kampanii'
      }
    }
    catch (err) {
      return {
        status: 'error',
        message: err.message || err
      }
    }
  }

  public async importOffersFromAnotherCampaign(currentCampaignKey: string, campaignToImportFromKey: string, selectedOfferIds) {
    const req = await this.func.importOffersFromAnotherCampaign(currentCampaignKey, campaignToImportFromKey, selectedOfferIds, this.authService.getUserEmail());
    return {
      message: req.request.message,
      status: req.status
    };
  }

  getCampaignData = async (campaign_key) => await this.db.collection('campaigns')
    .doc(campaign_key.substr(campaign_key.length - 4)).get().toPromise()
    .then(snap => {
      const d: any = snap.data();
      return d.campaigns[campaign_key];
    }
    );

  public async putOfferToCampaign(campaign_key: string, offer: any) {
    try {
      let newCampaignAttributes: any = {};
      const campaignAttributes = (await this.campaignAttributesRef.doc(campaign_key).get().toPromise());

      if (campaignAttributes.exists) {
        newCampaignAttributes = campaignAttributes.data();
        newCampaignAttributes.labels = newCampaignAttributes.labels || {};
        newCampaignAttributes.offers = newCampaignAttributes.offers || {};
        if (!newCampaignAttributes.campaign) {
          const campaignData = await this.getCampaignData(campaign_key);
          newCampaignAttributes.campaign = campaignData;
        }
      } else {
        const campaignData = await this.getCampaignData(campaign_key);
        newCampaignAttributes = {
          campaign: campaignData,
          comments: '',
          labels: {},
          offers: {},
        };
      }

      newCampaignAttributes.offers[`${offer.id}`] = offer;
      await this.campaignAttributesRef.doc(campaign_key).set(newCampaignAttributes);
      return {
        status: 'success',
        message: 'dodano ofertę do kampanii'
      }
    }
    catch (err) {
      return {
        status: 'error',
        message: err.message || err
      }
    }
  }

  public async updateCampaignOfferAffiliate(campaign_key: string, offer_id: string, affiliate, operation_type: string) {
    if (!operation_type || operation_type === 'cancel') return;
    const camp_offer: any = await this.db.collection('campaign_offer')
      .doc(`${campaign_key}-${offer_id}`).get()
      .toPromise().then(snap => snap.data());

    // wykonanie zaplanowanej operacji
    if (operation_type === 'update') {
      camp_offer.campaign_affiliates[affiliate.id] = affiliate;
    }
    else if (operation_type === 'remove') {
      delete camp_offer.campaign_affiliates[affiliate.id];
    }

    // zapisanie zmienionych informacji do bazy
    return await this.setCampaignOffer(camp_offer.campaign_key, camp_offer.offer_id, camp_offer);
  }

  public async putOfferUrlToCampaignOffer(campaign_key: string, offer_id: string, offer_url: any) {
    let camp_offer: any = await this.db.collection('campaign_offer')
      .doc(`${campaign_key}-${offer_id}`).get()
      .toPromise().then(snap => snap.data());

    if (!camp_offer) {
      const campaign: any = await this.getCampaign(campaign_key);
      const campaign_attr: any = (await this.campaignAttributesRef.doc(campaign_key).get().toPromise()).data();

      const campaign_offer = campaign_attr.offers[offer_id];
      const now = this.now_formated();
      const user_email = this.authService.getUserEmail();

      camp_offer = {
        campaign_key,
        campaign, campaign_offer,
        offer_id: offer_id,
        campaign_affiliates: {},
        campaign_offer_urls: {},
        last_change_date: now,
        last_change_person: user_email
      };
    }

    camp_offer.campaign_offer_urls[offer_url.id] = {
      ...offer_url,
      adding_date: this.now_formated(),
      adding_person: this.authService.getUserEmail()
    };

    return await this.setCampaignOffer(camp_offer.campaign_key, camp_offer.offer_id, camp_offer);
  }

  public async removeOfferUrlToCampaignOffer(campaign_key: string, offer_id: string, offer_url_id: any) {
    const camp_offer: any = await this.db.collection('campaign_offer')
      .doc(`${campaign_key}-${offer_id}`).get()
      .toPromise().then(snap => snap.data());

    delete camp_offer.campaign_offer_urls[offer_url_id];

    return await this.setCampaignOffer(campaign_key, offer_id, camp_offer);
  }

  public async putAffiliateToCampaignOffer(campaign_key: string, offer: any, affiliate: any) {
    try {
      const ho_payment: any =
        (await this.func.get_offer_affiliate_payment(offer.id, affiliate.id)).data.response.data;

      const now = this.now_formated();
      const user_email = this.authService.getUserEmail();
      const doc_key = `${campaign_key}-${offer.id}`;
      const doc_data = (await this.db.collection('campaign_offer').doc(doc_key).get().toPromise());
      let campaign_offer_data: any = {};
      if (doc_data.exists) {
        campaign_offer_data = doc_data.data();
      }
      else {
        const campaign_affiliates: any = {};
        campaign_offer_data = {
          campaign_offer_urls: {},
          campaign_affiliates
        };
      }

      const volume = null;
      const payout_type = ho_payment.payout_type;
      const revenue_type = ho_payment.revenue_type;
      const payout = payout_type.includes('percent') ?
        +ho_payment.percent_payout : +ho_payment.payout;
      const revenue = revenue_type.includes('percent') ?
        +ho_payment.percent_revenue : +ho_payment.revenue;

      const camp_affiliate = {
        id: affiliate.id,
        name: affiliate.company,
        status: affiliate.status,
        ref_id: affiliate.ref_id,
        date_added: affiliate.date_added,
        ho_payout: ho_payment.payout, ho_percent_payout: ho_payment.percent_payout,
        ho_revenue: ho_payment.revenue, ho_percent_revenue: ho_payment.percent_revenue,
        volume, payout, payout_type, revenue, revenue_type,
        adding_date: now,
        adding_person: user_email
      };
      campaign_offer_data.campaign_affiliates[camp_affiliate.id] = camp_affiliate;
      const offer_data = {
        id: offer.id,
        name: offer.name,
        status: offer.status,
        advertiser_id: offer.advertiser_id,
        payout_type: offer.payout_type,
        revenue_type: offer.revenue_type
      };

      const campaign_data: any = await this.getCampaign(campaign_key);

      campaign_offer_data = {
        ...campaign_offer_data,
        campaign_key,
        campaign: campaign_data,
        offer_id: offer.id,
        campaign_offer: offer_data,
        last_change_date: now,
        last_change_person: user_email
      };

      await this.db.collection('campaign_offer').doc(doc_key).set(campaign_offer_data);

      return {
        status: 'success',
        message: 'dodano wydawcę do oferty w  kampanii'
      }
    }
    catch (err) {
      console.log('putAffiliateToCampaignOffer: error: ', err);
      return {
        status: 'error',
        message: err.message || err
      }
    }
  }

  public now_formated() {
    const now = new Date();
    const
      yyyy = now.getFullYear(),
      MM = ('0' + (now.getMonth() + 1).toString()).slice(-2),
      dd = ('0' + now.getDate()).slice(-2),
      HH = ('0' + now.getHours()).slice(-2),
      mm = ('0' + now.getMinutes()).slice(-2),
      ss = ('0' + now.getSeconds()).slice(-2);

    return `${yyyy}-${MM}-${dd} ${HH}:${mm}:${ss}`;
  }

  public resetCampaigns() {
    this.year_campaigns = {};
  }

  public getCampaignAttributesObservable(campaign_key: string) {
    return this.campaignAttributesRef.doc(campaign_key).snapshotChanges();
  }

  public async getCampaignAttributes(campaign_key: string): Promise<ICampaignAttributes> {
    const campaigAttributes = (await this.campaignAttributesRef.doc(campaign_key).get().toPromise()).data() as ICampaignAttributes;
    return campaigAttributes;
  }

  public async getCampaignInfo(campaign_key: string): Promise<ICampaignInfo> {
    const camp: ICampaign = await this.getCampaign(campaign_key);
    const attr: ICampaignAttributes = (await this.campaignAttributesRef.doc(campaign_key).get().toPromise()).data() as ICampaignAttributes;
    const data: ICampaignInfo = { ...camp, ...attr };
    return data;
  }

  public async getCampaignOfferInfo(campaign_key: string, offer_id: string): Promise<ICampaignOffer> {
    const doc_key = `${campaign_key}-${offer_id}`;
    const offer_data: ICampaignOffer = (await this.db.collection('campaign_offer').doc(doc_key).get().toPromise()).data() as ICampaignOffer;
    return offer_data;
  }

  public getCampaignOffer(campaign_key: string, offer_id: string): Observable<Action<DocumentSnapshot<ICampaignOffer>>> {
    const doc_key = `${campaign_key}-${offer_id}`;
    return this.db.collection('campaign_offer').doc(doc_key).snapshotChanges() as Observable<Action<DocumentSnapshot<ICampaignOffer>>>;
  }

  public async getCampaignOfferAffiliate(campaign_key: string, offer_id: string, affiliate_id: string): Promise<{ affiliate: ICampaignAffiliate, camp_offer: ICampaignOffer }> {
    const doc_key = `${campaign_key}-${offer_id}`;
    let data = await this.db.collection('campaign_offer').doc(doc_key).get().toPromise();
    if (!data.exists) return null;
    const camp_offer: ICampaignOffer = data.data() as ICampaignOffer;
    const affiliate: ICampaignAffiliate = camp_offer.campaign_affiliates[affiliate_id];
    return { affiliate, camp_offer };
  }

  public async setCampaignOffer(campaign_key: string, offer_id: string, data: any) {
    const doc_key = `${campaign_key}-${offer_id}`;
    try {
      await this.db.collection('campaign_offer').doc(doc_key).set(data, { merge: false });
      return {
        status: 'success',
        message: 'zapisano dane oferty w kampanii'
      };
    }
    catch (err) {
      return {
        status: 'error',
        message: err.message || err
      };
    }
  }

  public async removeCampaignOffer(campaign_key: string, offer_id: string) {
    const doc_key = `${campaign_key}-${offer_id}`;
    try {
      await this.db.collection('campaign_offer').doc(doc_key).delete();
      const campaign_attributes_req = await this.campaignAttributesRef.doc(campaign_key).get().toPromise();
      if (campaign_attributes_req.exists) {
        const campaign_attributes: any = campaign_attributes_req.data();
        delete campaign_attributes.offers[offer_id];
        await this.campaignAttributesRef.doc(campaign_key).set(campaign_attributes, { merge: false });
      }
      return {
        status: 'success',
        message: 'usunięto dane oferty z kampanii'
      };
    }
    catch (err) {
      return {
        status: 'error',
        message: err.message || err
      };
    }
  }

  public async getCampaign(campaign_no: string) {
    const campaign_key = campaign_no.replace(/\//g, '_');
    try {
      if (!this.year_campaigns[this.aktywny_rok]) {
        this.year_campaigns[this.aktywny_rok] =
          ((await this.db.collection('campaigns').doc(`${this.aktywny_rok}`)
            .get().toPromise())
            .data() as any).campaigns;
      }

      return this.year_campaigns[this.aktywny_rok][campaign_key] || {};
    }
    catch (err) {
      return {};
    }
  }

  public async getCampaignsWithOfferAndPeriod(startDate, endDate, offerId, affiliateId) {
    const campaignOffers = (await this.db
      .collection("campaign_offer")
      .ref
      .where("campaign_offer.id", "==", offerId)
      .where("campaign.data_rozpoczecia", "<=", endDate)
      .get());

    const campaignOffersWithNoSpecifiedAffiliate = [];


    for (let i = 0; i < campaignOffers.docs.length; i++) {
      const data: any = campaignOffers.docs[i].data();
      if (data && data.campaign_affiliates && data.campaign.data_zakonczenia >= startDate && !data.campaign_affiliates[affiliateId]) {
        campaignOffersWithNoSpecifiedAffiliate.push(data);
      }
    }

    return campaignOffersWithNoSpecifiedAffiliate;
  }

  // public async setCampaignStartDateInHo(campaign_key: string, campaignStartDateInHo: Date) {
  //   const campaignAttributes = (await this.campaignAttributesRef.doc(campaign_key).get().toPromise()).data() || {};
  //   campaignAttributes["campaignStartDateInHo"] = campaignStartDateInHo;
  //   await this.campaignAttributesRef.doc(campaign_key).set(campaignAttributes);
  // }

  // public async setCampaignEndDateInHo(campaign_key: string, campaignEndDateInHo: Date) {
  //   const campaignAttributes = (await this.campaignAttributesRef.doc(campaign_key).get().toPromise()).data() || {};
  //   campaignAttributes["campaignEndDateInHo"] = campaignEndDateInHo;
  //   await this.campaignAttributesRef.doc(campaign_key).set(campaignAttributes);
  // }

  public async updateCampaignPeriodInHoUserDefined(campaign_key: string, isCampaignPeriodInHoUserDefined: boolean, campaignStartDateInHo: Date, campaignEndDateInHo: Date) {
    const campaignAttributes = (await this.campaignAttributesRef.doc(campaign_key).get().toPromise()).data() || {};
    if (isCampaignPeriodInHoUserDefined != undefined)
      campaignAttributes["isCampaignPeriodInHoUserDefined"] = isCampaignPeriodInHoUserDefined;
    if (campaignStartDateInHo)
      campaignAttributes["campaignStartDateInHo"] = campaignStartDateInHo;
    if (campaignEndDateInHo)
      campaignAttributes["campaignEndDateInHo"] = campaignEndDateInHo;
    await this.campaignAttributesRef.doc(campaign_key).set(campaignAttributes);

    const campaignStats: any = (await this.db.collection("campaign_stats").doc(campaign_key).get().toPromise()).data() || {};
    campaignStats.last_refresh_date = new Date().getTime();
    await this.db.collection("campaign_stats").doc(campaign_key).set(campaignStats);
  }
}
