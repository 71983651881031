import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { from, Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { TokenService } from '../repozytorium/token.service';
import { TokenAuthService } from '../environment/token-auth.service';
import { AuthService } from '../auth/auth.service';
import firebase from 'firebase';
import { BreadcrumbService } from '../environment/breadcrumb.service';

const tokenStorageName: string = "pae_token";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(private tokenService: TokenService, private authService: AuthService, private tokenAuthService: TokenAuthService, private breadcrumb: BreadcrumbService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handle(request, next))
    }

    async handle(request: HttpRequest<any>, next: HttpHandler) {
        if (!request.headers.get('Authorization')?.includes('Bearer')) {

            let token: string = sessionStorage.getItem(tokenStorageName);
            let shouldGetNewToken = false;

            // if())

            if (token) {
                const a = this.tokenService.decodeToken(token);
                const tokenExp = new Date(a.exp *1000);
                const now = new Date();
                now.setMinutes(now.getMinutes() - environment.tokenRefreshBeforeExpirationInMinutes)
                if (now > tokenExp) {
                    shouldGetNewToken = true;
                }
            }else{
                shouldGetNewToken = true;
            }

            if (shouldGetNewToken && this.authService.user) {
                //     sessionStorage.removeItem('pae_token');
                //     if(this.authService.user.email.includes('@groupone.com.pl')){

                //         const googleToken = await firebase.auth().currentUser.getIdToken();
                //         const resp = await this.tokenAuthService.login(googleToken);
                //         token = resp.body.data
                //         sessionStorage.setItem('pae_token', token);

                //     }else{
                //         this.breadcrumb.sendErrorMessage("Twoja sesja wygasła. Zaloguj się ponownie")
                //         await this.authService.logout();
                //     }
                this.breadcrumb.sendErrorMessage("Twoja sesja wygasła. Zaloguj się ponownie");
                await this.authService.logout();
            }
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });

            // Important: Note the .toPromise()
        }
        return next.handle(request).toPromise()
    }
}