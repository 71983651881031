import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CampaignST } from "../../models/CampaignST";

@Component({
  selector: 'app-new-campaign',
  templateUrl: './new-campaign.component.html',
  styleUrls: ['./new-campaign.component.css']
})
export class NewCampaignComponent implements OnInit {
  public formNewCampaign = new FormGroup({ 
      name: new FormControl('', { validators: [ Validators.required, Validators.minLength(3) ] }),
      period_start: new FormControl(null, { validators: [ Validators.required ] }), 
      period_end: new FormControl(null, { validators: [ Validators.required ] }), 
      comments: new FormControl('')
  });

  newCampaignAttributes() {
    return this.formNewCampaign.value;
  }

  constructor(
    public dialogRef: MatDialogRef<NewCampaignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CampaignST) {
  }

  Close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }
}