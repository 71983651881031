import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DaneArchiwalneService {
  baseUrl;

  public pobierz_dane_archiwalne(rok, miesiac, id_wydawcy): Observable<any> {

    const url: string = `${this.baseUrl}${rok}/${miesiac}/${id_wydawcy}`

    return this.http.get<any>(url);
  }

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.affiliateExpertArchiveDataBasePath}DaneArchiwalne/`;
  }
}
