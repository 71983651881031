import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { CustomFunctionsService } from './custom-functions.service';
import { RepozytoriumService } from './repozytorium.service';
import { TokenService } from './token.service';


@Component({
  selector: 'app-repozytorium',
  templateUrl: './repozytorium.component.html',
  styleUrls: ['./repozytorium.component.css']
})
export class RepozytoriumComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  clearanceSlipDisplayMode: string = 'default';
  clearanceSlipList = [];
  displayedColumns: string[] = [
    'item_name',
    'action_button',
    'created_by',
    'created_when'
  ];

  constructor(private route: ActivatedRoute, private RepozytoriumService: RepozytoriumService, private TokenService: TokenService, private customFunctionService: CustomFunctionsService) {
    this.dataSource = new MatTableDataSource([]);
  }

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() viewTitle = "Klient repozytorium";

  token = this.route.snapshot.params['token'];
  isLoading = true;
  error;
  menuButtonList = [];
  positionButtonList = [];
  path = "";
  decodedToken;


  async ngOnInit() {
    try {
      if (!this.token) {
        throw new Error("Brak tokenu do repozytorium");
      }


      const itemList = await this.RepozytoriumService.getRepositoryData(this.token);
      this.dataSource.data = itemList;
      this.initTable();
      const decodedToken = this.TokenService.decodeToken(this.token);
      if (!decodedToken.custom.repository_client) {
        throw Error("Token nie jest przeznaczony dla aplikacji klienckiej")
      }
      this.menuButtonList = decodedToken.custom.repository_client.global_actions;
      this.positionButtonList = decodedToken.custom.repository_client.item_actions;
      this.isLoading = false;
      this.decodedToken = decodedToken;
    } catch (e) {
      this.error = "Błąd: " + e.message || e;
      throw e;
    }
  }

  initTable() {
    this.dataSource.sort = this.sort;
    this.paginator._intl.itemsPerPageLabel = 'rozmiar strony:';
    this.paginator._intl.firstPageLabel = 'pierwsza strona';
    this.paginator._intl.lastPageLabel = 'ostatnia strona';
    this.paginator._intl.nextPageLabel = 'następna strona';
    this.paginator.pageSize = 20;
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.previousPageLabel = 'poprzednia strona';
  }
  
  
getFormattedDateWithHour(date: any) {
  return new Date(date.seconds ? date.seconds * 1000 : date).toLocaleDateString('pl-PL', { hour: '2-digit', minute: '2-digit' });
}

getFormattedDate(date: any) {
  return new Date(date.seconds ? date.seconds * 1000 : date).toLocaleDateString('pl-PL');
}

setclearanceSlipColumns() {
  // tslint:disable-next-line: max-line-length
  this.dataSource.data = this.clearanceSlipDisplayMode === 'default' ? this.clearanceSlipList.filter(e => e.status === 'in_progress') : this.clearanceSlipList;
  this.initTable();
}

applyFilter(searchedWord: string) {
  this.dataSource.filter = searchedWord.trim().toLocaleLowerCase();
}

async changeFolder(folderName) {
  const newPath = this.path + "/" + folderName;
  await this.refreshData(newPath);
  this.path = newPath;
}

async escapeFolder() {
  const newPath = this.path.substring(0, this.path.lastIndexOf('/'));
  await this.refreshData(newPath);
  this.path = newPath;
}

async refreshData(path = ""){
  const itemList = await this.RepozytoriumService.getRepositoryData(this.token, path);
  this.dataSource.data = itemList;
  this.initTable();
}

async invokeFunctionFromCustomFunctions(functionName, param = null){
  if (param && typeof param == "string") {
    if (param.includes("%actual_path%")) {
      param.replace("%actual_path%", this.path)
    } else if (param.includes("%actual_name%")) {
      param.replace("%actual_name%", this.decodedToken.issuer)
    }
  }
  await this.customFunctionService.invokeFunctionByName(functionName, param);
  await this.refreshData(this.path);

}
}

