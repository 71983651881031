import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navi-bar',
  templateUrl: './navi-bar.component.html',
  styleUrls: ['./navi-bar.component.css']
})
export class NaviBarComponent implements OnInit {
  isAdminModule: boolean = false;
  repositoryApiUrl = "repozytorium/" + environment.repositoryToken;

  constructor(public authService: AuthService, private router: Router) {

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        var segments = event.urlAfterRedirects.split('/');
        this.isAdminModule = segments.length > 0 && segments[1] === 'admin';
      }
    });
  }

  checkUserPermissions = (actionName: string) => this.authService.permissions ? this.authService.permissions.includes(actionName) : false;

  ngOnInit() {

  }

}