import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ImportService } from '../import-data/import.service';
import { startWith, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, _countGroupLabelsBeforeOption } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { PublisherService } from './publisher.service';
import { NumberService } from '../planner/shared/number.service';
import { InvoiceService } from '../shared/invoice.service';
import { BreadcrumbService } from '../environment/breadcrumb.service';
import { FileuploadService } from '../shared/fileupload.service';

const moment = _rollupMoment || _moment;
const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pl' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class PublisherComponent implements OnInit {

  constructor(private publisherService: PublisherService, private breadcrumb: BreadcrumbService, private fileUploadService: FileuploadService, private numberService: NumberService, public dialog: MatDialog, private importService: ImportService, private router: Router, private authService: AuthService, private invoiceService: InvoiceService, private breadcrumbService: BreadcrumbService, private auth: AuthService, private route:ActivatedRoute) { }
  exampleData = [];

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  public barChartLabels = [];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [
    { data: [], label: 'kwota do rozliczenia' },
  ];

  importConfigurationForm: FormGroup;
  dataSource: MatTableDataSource<any>;
  config;
  affiliates;
  offers;
  startDate = this.getPreviousMonthStartDate();
  endDate = this.getPreviousMonthLastDate();
  filteredAffiliateOptions: Observable<any[]>;
  publisherIsSelected = false;
  currentAffiliate;
  canChangePublisher = this.auth.getUserEmail().endsWith("@groupone.com.pl");
  MAX_CHART_ITEMS = 15;
  error;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[] = [
    'year',
    'month',
    'st_number',
    'offer_name',
    'payout_type',
    'revenue',
    'action_amount',
    'cost_to_be_payed',
    'validation_file',
    'invoice_issued',
    'comment',
  ];

  onSubmit() {
    return;
  }

  moment = _moment;

  async ngOnInit() {
    this.importConfigurationForm = new FormGroup({
      affiliateName: new FormControl('', Validators.required),
      offer: new FormControl('', Validators.required),
      startDate: new FormControl(Validators.required),
      endDate: new FormControl(Validators.required),
    });

    this.config = await this.importService.getConfig();
    this.affiliates = this.config.affiliates;
    this.dataSource = new MatTableDataSource([]);

    this.filteredAffiliateOptions = this.importConfigurationForm.controls.affiliateName.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterAffiliateOption(value))
      );

    const userEmail = this.authService.getUserEmail();

    let affiliateUserId = this.authService.getAffiliateUserId();

    const filterParams = sessionStorage.getItem('filterParams');
    let parsedFilterParams;

    if (filterParams) {
      parsedFilterParams = JSON.parse(filterParams);
    }

    if (!affiliateUserId && !userEmail.endsWith("@groupone.com.pl")) {
        this.breadcrumbService.sendErrorMessage("Wystąpił błąd autoryzacji");
        this.auth.logout();
        this.router.navigate["login"];
    } 

    if (this.affiliates[affiliateUserId] && this.affiliates[affiliateUserId].Affiliate && this.affiliates[affiliateUserId].Affiliate.company) {

      if (!affiliateUserId) {
        this.breadcrumbService.sendErrorMessage("Użytkownik systemu HasOffers nie jest wydawcą");
        this.auth.logout();
        this.router.navigate["login"];
      }

      if (!userEmail.endsWith("@groupone.com.pl")) {
        this.currentAffiliate = this.affiliates[affiliateUserId]?.Affiliate?.company + " (" + affiliateUserId + ")" ?? "Nie znaleziono użytkownika";
        this.publisherIsSelected = true;
        this.canChangePublisher = false;

        if (parsedFilterParams) {
          if (parsedFilterParams.startPeriodDate && parsedFilterParams.endPeriodDate) {
            this.startPeriodDate.setValue(moment(parsedFilterParams.startPeriodDate));
            this.endPeriodDate.setValue(moment(parsedFilterParams.endPeriodDate));
          }
        }

        let month = this.formatDateComponent((+this.startPeriodDate.value.month() + 1).toString());
        let year = this.startPeriodDate.value.year() + "";
        let endMonth = this.formatDateComponent((+this.endPeriodDate.value.month() + 1).toString());
        let endYear = this.endPeriodDate.value.year() + "";



        this.exampleData = await this.publisherService.getAffiliateOffers(month, year, endMonth, endYear, this.affiliates[affiliateUserId].Affiliate.ref_id);
        this.exampleData = this.exampleData.filter(d => (d.campaign_stats && d.affiliate_stats));
        this.exampleData.forEach(e => {
          e.amount_to_be_settled = this.roundNumber(this.getAmountToBeSettled(e, e["campaign_key-offer_id"].substring(14)), 2);
        });

        this.refreshChartData();

        this.dataSource.data = this.exampleData || [];
        this.initTable();
        this.campaignFilterParameterChanged();
      }
    } else {
      if (!this.canChangePublisher) {
        this.breadcrumbService.sendErrorMessage("Użytkownik systemu HasOffers nie jest wydawcą");
        this.auth.logout();
        this.router.navigate["login"];
      }
      this.currentAffiliate = "";

      if (parsedFilterParams) {
        if (parsedFilterParams.startPeriodDate && parsedFilterParams.endPeriodDate && parsedFilterParams.currentAffiliate) {
          this.startPeriodDate.setValue(moment(parsedFilterParams.startPeriodDate));
          this.endPeriodDate.setValue(moment(parsedFilterParams.endPeriodDate));
          this.currentAffiliate = parsedFilterParams.currentAffiliate;
          this.onAffiliateChanged("current");
        }
      }
    }

    this.dataSource.data = this.exampleData || [];
    this.initTable();
  }

  getAffiliateData() {

  }

  getTextFromBetween(startChar, endChar, text) {
    const startIndex = text.lastIndexOf(startChar);
    const endIndex = text.lastIndexOf(endChar);
    return text.substring(startIndex + 1, endIndex);
  }



  getAmountToBeSettled(element, offerId) {
    if (element?.custom?.affiliate_cost) {
      return element.custom.affiliate_cost;
    }

    const offerCampaignStats = element.campaign_stats.filter(e => e.Stat.offer_id === offerId);

    if (element.affiliate_stats.payout_type === 'cpc') {
      const allClicks = offerCampaignStats.map(e => e.Stat.unique_clicks.toString().replace(",", ".")).reduce((a, b) => +a + +b);
      const settledClicks = Math.min(allClicks || 0, +element.affiliate_stats.volume?.toString().replace(",", ".") || 0)
      return (settledClicks) * +element.affiliate_stats.payout?.toString().replace(",", ".");
    }
    else if (element.affiliate_stats.payout_type === 'cpa_percentage') {
      const totalSaleAmount = offerCampaignStats.map(e => e.Stat.sale_amount.toString().replace(",", ".")).reduce((a, b) => +a + +b);
      return (+totalSaleAmount || 0) * +(+element.affiliate_stats.payout?.toString().replace(",", ".") / 100);
    }
    else if (element.affiliate_stats.payout_type === 'cpa_flat') {
      const totalCoversions = offerCampaignStats.map(e => e.Stat.conversions.toString().replace(",", ".")).reduce((a, b) => +a + +b);
      const volume = +element.affiliate_stats.volume?.toString().replace(",", ".");
      let amountToSettle;
      if (volume && volume > 0) {
        amountToSettle = Math.min(totalCoversions || 0, volume || 0)
      } else {
        amountToSettle = totalCoversions;
      }
      return (+amountToSettle?.toString().replace(",", ".") || 0) * +(+element.affiliate_stats.payout?.toString().replace(",", "."));
    }
    else return 0;
  }

  getActionCount(element) {
    const offerId = element["campaign_key-offer_id"].substring(14);
    const offerCampaignStats = element.campaign_stats.filter(e => e.Stat.offer_id === offerId);
    if (element.affiliate_stats.payout_type === 'cpc') {
      const stats =  offerCampaignStats.map(e => e.Stat.unique_clicks.toString().replace(",", ".")).reduce((a, b) => +a + +b);
      return Math.min(stats || 0, +element.affiliate_stats.volume?.toString().replace(",", ".") || 0)

    }
    else if (element.affiliate_stats.payout_type === 'cpa_percentage') {
      return offerCampaignStats.map(e => e.Stat.sale_amount.toString().replace(",", ".")).reduce((a, b) => +a + +b);
    }
    else if (element.affiliate_stats.payout_type === 'cpa_flat') {
      const conversions = offerCampaignStats.map(e => e.Stat.conversions.toString().replace(",", ".")).reduce((a, b) => +a + +b)
      const volume = +element.affiliate_stats.volume?.toString().replace(",", ".");
      let amountToSettle;
      if (volume && volume > 0) {
        return Math.min(conversions || 0, volume || 0)
      } else {
        return conversions;
      }
    }
    else return "-";
  }

  private _filterAffiliateOption(value: string): string[] {
    let filterValue = value?.toLowerCase() ?? "";
    const affiliatesList = Object.keys(this.affiliates).map(k => this.affiliates[k]);
    const affiliateRefId = this.authService.getAffiliateUserId();
    if (affiliateRefId) {
      return affiliatesList.filter(a => a.Affiliate.ref_id == affiliateRefId);
    }
    return affiliatesList ? affiliatesList.filter(option => option.Affiliate && option.Affiliate.company && option.Affiliate.company?.toLowerCase().includes(filterValue)) : [];
  }

  convertToEpochMiliseconds(date) {
    return (date.seconds ? (date.seconds * 1000) : (date instanceof Date ? date.getTime() : new Date(date).getTime()));
  }

  diffHours(dt1, dt2) {
    var diff = this.convertToEpochMiliseconds(dt2) - this.convertToEpochMiliseconds(dt1);
    diff /= 3600000;
    return Math.abs(Math.floor(diff));
  }

  trimZeroes(text) {
    for (var i = 0; i < text.length; i++) {
      if (text[i] !== '0') {
        return text.substring(i);
      }
    }
  }

  async onInvoiceIssuedChanged(e, index) {
    const data = this.exampleData[index];
    const offerId = data["campaign_key-offer_id"].substring(14);

    //sprawdzić czy custom added
    await this.invoiceService.offerInvoiceVisibilitySetIssuedStatus(offerId, data.campaign_key, data.affiliate_stats.id, data.affiliate_stats.ref_id, data.data_rozpoczecia, e.checked, null, false);


    await this.onAffiliateChanged("current");
  }

  initTable() {
    this.paginator._intl.itemsPerPageLabel = "rozmiar strony:";
    this.paginator._intl.firstPageLabel = "pierwsza strona";
    this.paginator._intl.lastPageLabel = "ostatnia strona";
    this.paginator._intl.previousPageLabel = "poprzednia strona";
    this.paginator._intl.nextPageLabel = "następna strona";
    this.paginator.pageSize = 100;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  startPeriodDate = new FormControl(moment(this.getPreviousMonthStartDate()));
  endPeriodDate = new FormControl(moment(this.getPreviousMonthStartDate()));

  chosenStartYearHandler(normalizedYear: _moment.Moment) {
    const ctrlValue = this.startPeriodDate.value;
    ctrlValue.year(normalizedYear.year());
    this.validateMonthPeriod();
    this.startPeriodDate.setValue(ctrlValue);
  }

  async chosenStartMonthHandler(normalizedMonth: _moment.Moment, datepicker: MatDatepicker<_moment.Moment>) {
    const ctrlValue = this.startPeriodDate.value;
    ctrlValue.month(normalizedMonth.month());
    this.startPeriodDate.setValue(ctrlValue);
    datepicker.close();
    this.validateMonthPeriod();
    await this.onAffiliateChanged("current");
    this.campaignFilterParameterChanged();
  }

  chosenEndYearHandler(normalizedYear: _moment.Moment) {
    const ctrlValue = this.endPeriodDate.value;
    ctrlValue.year(normalizedYear.year());
    this.validateMonthPeriod();
    this.endPeriodDate.setValue(ctrlValue);
  }

  async chosenEndMonthHandler(normalizedMonth: _moment.Moment, datepicker: MatDatepicker<_moment.Moment>) {
    const ctrlValue = this.endPeriodDate.value;
    ctrlValue.month(normalizedMonth.month());
    this.endPeriodDate.setValue(ctrlValue);
    datepicker.close();
    this.validateMonthPeriod();
    await this.onAffiliateChanged("current");
    this.campaignFilterParameterChanged();
  }

  validateMonthPeriod() {
    if (this.startPeriodDate.value > this.endPeriodDate.value) {
      this.error = "Data początkowa nie może być późniejsza niż końcowa"
    } else {
      this.error = null;
    }
  }

  campaignFilterParameterChanged() {
    const stringifiedFilters = JSON.stringify({ currentAffiliate: this.currentAffiliate, startPeriodDate: this.startPeriodDate.value, endPeriodDate: this.endPeriodDate.value })
    sessionStorage.setItem('filterParams', stringifiedFilters);
  }

  applyFilter(searchedWord: string) {
    this.dataSource.filter = searchedWord.trim().toLocaleLowerCase();
  }

  getPreviousMonthStartDate() {
    let x = new Date();
    return new Date(x.getFullYear(), x.getMonth() - 1, 1);
  }

  getPreviousMonthLastDate() {
    let x = new Date();
    x.setDate(0);
    return x;
  }

  formatDateComponent(text) {
    let processedText = text.toString();
    if (processedText.length == 1) {
      processedText = '0' + processedText;
    }

    return processedText;
  }

  getFormattedDateWithHour(date: any) {
    if (!date) return;
    return new Date(date.seconds ? date.seconds * 1000 : date).toLocaleDateString('pl-PL', { hour: '2-digit', minute: '2-digit' });
  }

  openInvoiceInstruction() {
    this.router.navigate(['/publisher-invoice-instruction']);
  }

  exportParams() {
    return {
      fileName: `statystyki - ${(new Date()).toLocaleString('sv-SE')}`,
      sheet: 'statystyki',
      ignoreEC: true,
      compression: true,
    };
  }

  redirectToArchive(){
    this.router.navigate(['../','publisher-data-archive'] ,{ relativeTo: this.route });
  }

  getRefId(option) {
    return option.Affiliate.id;
  }

  roundNumber(num, dec) {
    return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
  }

  getTotalPayout = function () {
    let totalAmountToBePaid = this.dataSource?.filteredData['0'] ? this.dataSource?.filteredData?.length > 1 ? this.dataSource?.filteredData.map(e => e.amount_to_be_settled).reduce((a, b) => +a + +b) : this.dataSource?.filteredData['0'].amount_to_be_settled : 0;
    return this.roundNumber(totalAmountToBePaid, 2);
  }

  onAffiliateChanged = async (e) => {
    if (e== '' || e?.option?.value == "-") return;
    const id = e?.option?.value ? this.getTextFromBetween("(", ")", e.option.value) : this.getTextFromBetween("(", ")", this.currentAffiliate);
    if (id == "Brak RefId") return;
    this.error = "";
    const affiliateUserId = +id;

    const affiliate = Object.keys(this.affiliates).map(k => this.affiliates[k]).filter(a => a.Affiliate.id == id)[0];

    if (!affiliate) {
      this.exampleData = [];
      this.refreshChartData();
      this.initTable();
      this.dataSource.data = [];
      this.error = "Brak danych dla affilianta: " + id;

      return;
    }

    this.currentAffiliate = (affiliate?.Affiliate?.company && affiliateUserId) ? affiliate?.Affiliate?.company + " (" + affiliateUserId + ")" : "Nie znaleziono użytkownika";
    this.publisherIsSelected = true;
    const month = this.formatDateComponent((+this.startPeriodDate.value.month() + 1).toString());
    const year = this.startPeriodDate.value.year() + "";
    const endMonth = this.formatDateComponent((+this.endPeriodDate.value.month() + 1).toString());
    const endYear = this.endPeriodDate.value.year() + "";

    this.exampleData = (await this.publisherService.getAffiliateOffers(month, year, endMonth, endYear, affiliate.Affiliate.ref_id));
    this.exampleData = this.exampleData.filter(d => (d.campaign_stats && d.affiliate_stats && (d.is_visible_for_affiliate || (d.custom&& d.custom.is_visible_for_affiliate))));
    this.exampleData.forEach(e => {
      e.amount_to_be_settled = this.roundNumber(this.getAmountToBeSettled(e, e["campaign_key-offer_id"].substring(14)), 2);
    });

    this.refreshChartData();

    this.dataSource.data = this.exampleData || [];
    this.initTable();
    this.campaignFilterParameterChanged();
  }

  navigateToOffer(e) {
    console.log("Nawiguję do oferty! " + e.currentTarget.innerText)
    this.router.navigate([`/planner/campaign/${e.currentTarget.innerText.replaceAll("/", "_")}`]);
  }

  //obsłużyć custom added
  async downloadReport(element) {
    this.fileUploadService.downloadItem(element.campaign_key, element.data_rozpoczecia.substring(5, 7), +element["campaign_key-offer_id"].substring(14), +element.affiliate_stats.ref_id, element.validation_report, false).subscribe(data => {
      if (data.status == 'error') {
        this.breadcrumb.sendErrorMessage(data.message);
      } else {

        const downloadedFileBlob = new Blob([data]);

        var a = document.createElement("a"), url = URL.createObjectURL(downloadedFileBlob);
        a.href = url;
        a.download = element.validation_report;
        document.body.appendChild(a);
        a.click();

        this.breadcrumb.sendInfoMessage("Pomyślnie pobrano raport walidacyjny");
      }

    }, error => {
      console.log(error);
    });
  }

  refreshChartData() {
    if (this.barChartData[0]?.data)
      this.barChartData[0].data = [];
    if (this.barChartData[1]?.data)
      this.barChartData[1].data = [];

    this.barChartLabels = [];

    if (this.exampleData) {

      const holder = {};

      this.exampleData.forEach(function (d) {
        if (holder.hasOwnProperty(d.offer_name)) {
          holder[d.offer_name] = holder[d.offer_name] + +d.amount_to_be_settled;
        } else {
          holder[d.offer_name] = +d.amount_to_be_settled;
        }
      });

      var obj2 = [];

      for (var prop in holder) {
        obj2.push({ offer_name: prop, value: holder[prop] });
      }

      const sortedData = obj2.sort((a, b) => parseFloat(b.value) - parseFloat(a.value)).slice(0, this.MAX_CHART_ITEMS);

      if (sortedData[0])
        sortedData.forEach(d => {
          this.barChartData[0].data.push(d.value);
          this.barChartLabels.push(d.offer_name);
        })
    }
  }

  getFormattedStNumber(stNumber) {
    return stNumber.replaceAll("_", "/")
  }

  getHoPayoutName(payoutName) {
    const names = {
      "cpa_percentage": "cps",
      "cpa_flat": "cpa",
      "cpc": "cpc",
      "cpm": "cpm"
    }
    return names[payoutName.toLocaleLowerCase()] ? names[payoutName.toLocaleLowerCase()] : payoutName;
  }
}
