import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BreadcrumbService } from './breadcrumb.service';
import { FsdbService } from './fsdb.service';
import { FbFunctionsService } from './fb-functions.service';
import { environment } from 'src/environments/environment';

@NgModule({
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    MatMenuModule, MatButtonModule, MatCheckboxModule, MatIconModule, MatInputModule,
    MatSelectModule, MatTableModule, MatNativeDateModule, MatDatepickerModule, MatFormFieldModule
  ],
  exports: [ 
    AngularFireModule, 
    AngularFireAuthModule,
    AngularFirestoreModule,
    MatMenuModule, MatButtonModule, MatCheckboxModule, MatIconModule, MatInputModule,
    MatSelectModule, MatTableModule, MatNativeDateModule, MatDatepickerModule, MatFormFieldModule    
  ],
  declarations: [],
  providers: [ ]
})
export class EnvironmentModule { 
  public static forRoot(): ModuleWithProviders<EnvironmentModule> {
    return {
        ngModule: EnvironmentModule,
        providers: [BreadcrumbService, FsdbService, FbFunctionsService]
    };
}
  constructor(@Optional() @SkipSelf() core: EnvironmentModule) {
    if(core) {
      console.error('ponowne załadowanie modułu Environment!');
      throw new Error('ponowne załadowanie modułu Environment!');
    }
  }
}
