import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FbFunctionsService } from '../environment/fb-functions.service';
import { ImportService } from './import.service';
import { MatDialog } from '@angular/material/dialog';
import { ImportConversionDataDialogComponent } from './import-conversion-data-dialog/import-conversion-data-dialog.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../shared/new-campaign/format-datepicker';
import { IConversionItem } from '../models/IConversionItem';
import { BreadcrumbService } from '../environment/breadcrumb.service';
import { CampaignsService } from '../planner/campaigns.service';
import { ImportSendDialogComponent } from './import-send-dialog/import-send-dialog.component';

@Component({
  selector: 'app-import-data',
  templateUrl: './import-data.component.html',
  styleUrls: ['./import-data.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pl' },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class ImportDataComponent implements OnInit {

  constructor(private firebaseFunctions: FbFunctionsService, private importService: ImportService, public dialog: MatDialog, private breadcrumbService: BreadcrumbService, private campaignService: CampaignsService) { }
  importConfigurationForm: FormGroup;
  conversionStates: string[] = ['automatycznie', 'approved', 'pending', 'rejected']
  filteredAffiliateOptions: Observable<any[]>;
  filteredOfferOptions: Observable<any[]>;
  dataSource: MatTableDataSource<any>;
  config;
  advertisers;
  offers;
  startDate = this.getPreviousMonthStartDate();
  endDate = this.getPreviousMonthLastDate();
  advertiserId;
  offerId;
  advertisersList;
  affiliates;
  tableDisplayMode = 'stats';
  conversionsRaw;
  offerValue;
  advertiserValue;
  conversionsAggregated;
  selections = ['doubled'];
  userUploadedConversions = [];
  onTextAreaValueChange = (v) => this.uploadedConversions = v;
  uploadedConversions = '';
  error;
  uploadMessage;
  downloadingStats = false;
  uploadingStatsToHo = false;
  statusForUploadedConversions = "automatycznie";
  minimalHoursToRefreshData = 12;
  importMode = 'automatycznie';
  updatedFields = [];
  refreshingAdvertisers = false;
  refreshingOffers = false;
  selectedImportConfig;
  downloadingStatsFromHo = false;
  errorLog = 'Załadowano poprawnie';
  validatedReportLines = 0;
  unmatchedConversionsAssigment = "SalesTube";
  showSimulation = false;
  conversionsToUploadDiff = [];
  uploadedUnmatchedConversionsAssignedToST = [];
  campaignsToBeModified = [];

  conversionIdHeaders = [];
  conversionTransactionHeaders = [];
  conversionStatusHeaders = [];
  conversionCommentColumn = []
  conversionApprovedAliases = [];
  conversionRejectedAliases = [];
  conversionPendingAliases = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[] = [
    'offer',
    'affiliate',
    'approved',
    'rejected',
    'pending'
  ];

  columnsStats: string[] = [
    'offer',
    'affiliate',
    'approved',
    'rejected',
    'pending',
    'message'
  ];

  columnUploadedData: string[] = [
    'advertiser_info',
    'status',
    'comment'
  ]

  columnsConversions: string[] = [
    'advertiser_info',
    'affiliate_id',
    'advertiser_id',
    'offer_id',
    'datetime',
    'sale_amount',
    'affiliate_info1',
    'status'
  ]

  statName = {
    "stats": "statystyki",
    "conversions": "konwersje",
    "client_data": "dane wydawcy",
    "simulation": "symulacja"
  }

  unmatchedConversionsAssigments = [
    "SalesTube",
    "ignoruj"
  ]

  //makieta
  filteredImportConfigurations = [];

  getUploadedDataRowsAmountInfo() {
    return (this.uploadedConversions && this.uploadedConversions.length) ? 'poprawnie załadowano ' + this.validatedReportLines + ' z ' + (this.uploadedConversions.split(/\r\n|\r|\n/).length - 1) + ' pozycji raportu' : 'brak załadowanych pozycji';
  }

  getUserUploadedConversionsLabelColor() {
    return this.getUploadedDataRowsAmountInfo() === 'brak załadowanych pozycji' ? 'red' : this.validatedReportLines === (this.uploadedConversions.split(/\r\n|\r|\n/).length - 1) ? 'green' : 'orange';
  }


  async ngOnInit() {
    this.importConfigurationForm = new FormGroup({
      affiliateName: new FormControl('', Validators.required),
      offer: new FormControl('', Validators.required),
      startDate: new FormControl(Validators.required),
      endDate: new FormControl(Validators.required),
    });

    await this.refreshAdvertisers();

    this.filteredAffiliateOptions = this.importConfigurationForm.controls.affiliateName.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterAffiliateOption(value))
      );

    this.filteredOfferOptions = this.importConfigurationForm.controls.offer.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterOfferOption(value))
      );

    this.dataSource = new MatTableDataSource([]);
    this.initTable();

    this.filteredImportConfigurations = Object.keys(this.config.import_configurations).map(k => this.config.import_configurations[k])
  }

  shouldRefresAffiliates(): boolean {
    return ((Math.floor((new Date()).getTime() / 1000) - this.config.last_advertiser_update.seconds)) > (this.minimalHoursToRefreshData * 3600)
  }

  async refreshAdvertisers(force: boolean = false) {
    this.refreshingAdvertisers = true;
    this.config = await this.importService.getConfig();
    if (force || this.shouldRefresAffiliates()) {
      this.config = (await this.firebaseFunctions.updateAdvertisersInImportConfig()).advertisers;
    }
    this.advertisers = Object.keys(this.config.advertisers).map(k => this.config.advertisers[k]);
    this.refreshingAdvertisers = false;
  }

  onTableModeChange() {
    if (this.tableDisplayMode == 'stats') {
      if (this.conversionsAggregated) {
        this.dataSource.data = this.conversionsAggregated;
      }
      this.displayedColumns = this.columnsStats;
    } else if (this.tableDisplayMode == 'conversions') {
      if (this.conversionsRaw && Object.keys(this.conversionsRaw).length > 0) {
        const conversionsList = Object.keys(this.conversionsRaw).map(k => this.conversionsRaw[k]);
        this.dataSource.data = conversionsList;
        this.dataSource.data.forEach(c => c.Conversion.affiliate_name = this.getAffiliateName(c.Conversion.affiliate_id));
      }
      this.displayedColumns = this.columnsConversions;
    } else if (this.tableDisplayMode == 'client_data') {
      if (this.userUploadedConversions) {
        this.dataSource.data = this.userUploadedConversions;
      }
      this.displayedColumns = this.columnUploadedData;
    } else if (this.tableDisplayMode == 'simulation') {
      if (this.userUploadedConversions) {
        this.dataSource.data = this.getSimulatedConversions();
      }
      this.displayedColumns = this.columnsStats;
    }
  }

  getSimulatedConversions() {
    return this.aggregateConversions(this.updatedFields);
  }


  convertToEpochMiliseconds(date) {
    return (date.seconds ? (date.seconds * 1000) : (date instanceof Date ? date.getTime() : new Date(date).getTime()));
  }

  diffHours(dt1, dt2) {
    var diff = this.convertToEpochMiliseconds(dt2) - this.convertToEpochMiliseconds(dt1);
    diff /= 3600000;
    return Math.abs(Math.floor(diff));
  }

  async uploadData() {
    this.error = undefined;

    try {

      await this.createSimulation(this.userUploadedConversions);

    } catch (err) {
      this.error = err.message || err;
    }
  }

  async createSimulation(uploadedConversions) {
    try {
      this.updatedFields = [];
      this.uploadedUnmatchedConversionsAssignedToST = [];
      this.campaignsToBeModified = [];

      if (!this.conversionsRaw) {
        throw new Error('Przed dopasowaniem konwersji pobierz dane z HasOffers')
      }

      const hasoffersAdvertiserInfoList = {}
      const conversionsRawList = Object.values(this.conversionsRaw);

      let sc: any;
      for (sc of conversionsRawList) {
        hasoffersAdvertiserInfoList[
          this.selections.includes('zero') ?
            this.trimZeroes(sc.Conversion.advertiser_info) :
            sc.Conversion.advertiser_info
        ] = true;
      }


      const userUploadedUnmatchedConversions = [];

      for (let uc of uploadedConversions) {
        if (!hasoffersAdvertiserInfoList[uc.Conversion.advertiser_info]) {
          userUploadedUnmatchedConversions.push(uc);
        } else {
          const status = uc.Conversion.status;
          const comment = uc.Conversion.comment;

          const filteredConversions = conversionsRawList
            .filter((c: any) => c.Conversion.advertiser_info == uc.Conversion.advertiser_info)
          let rc: any;
          for (rc of filteredConversions) {
            this.updatedFields.push({ advertiser_info: rc.Conversion.advertiser_info, id: rc.Conversion.id, status, comment })
          }
        }
      }

      if ((this.selections.includes('not_found'))) {
        const uploadedAdvertiserInfoList = uploadedConversions.map(c => c.Conversion.advertiser_info);
        const unmatchedConversionsFromHo = conversionsRawList.filter((c: any) => !(uploadedAdvertiserInfoList.includes(c.Conversion.advertiser_info)));

        let uc: any;
        for (uc of unmatchedConversionsFromHo) {
          const conversion = conversionsRawList.find((cfl: any) => cfl.Conversion.id == uc.Conversion.id);
          if (conversion) {
            this.setConversionInUpdatedConversions(conversion);
          } else {
            throw new Error("Wystąpił błąd przy ustawianiu wartości niedopasowanej konwersji jako rejected o id: " + uc.Conversion.id);
          }

        }
      }

      if (this.selections.includes('doubled')) {
        this.markDuplicatesAsRejected(this.updatedFields)
      }

      if (this.unmatchedConversionsAssigment == "SalesTube") {
        //wepchnąć do zmienionych konwersji
        let c;
        if (userUploadedUnmatchedConversions.length) {
          const formattedStartDate = this.startDate.getFullYear() + "-" + this.formatDateComponent(this.startDate.getMonth() + 1) + "-" + this.formatDateComponent(this.startDate.getDate());
          const formattedEndDate = this.endDate.getFullYear() + "-" + this.formatDateComponent(this.endDate.getMonth() + 1) + "-" + this.formatDateComponent(this.endDate.getDate());
          this.campaignsToBeModified = await this.campaignService.getCampaignsWithOfferAndPeriod(formattedStartDate, formattedEndDate, this.getTextFromBetween('(', ')', this.offerId.option.value), 2540);

          for (c of userUploadedUnmatchedConversions)
            await this.assignConversionInUpdatedConversionsToST(c);
        }
      }

      this.conversionsToUploadDiff = this.getConversionsToUpload(this.updatedFields, this.conversionsRaw);
      this.showSimulation = true;

    } catch (err) {
      this.updatedFields = [];
      this.error = err.message || err;
    }
  }

  private setConversionInUpdatedConversions(conversion: any) {
    const updatedFieldsIndex = this.updatedFields.findIndex(a => a.id == conversion.Conversion.id);
    if (updatedFieldsIndex > -1) {
      this.updatedFields[updatedFieldsIndex].status = 'rejected';
    } else {
      this.updatedFields.push({
        id: conversion.Conversion.id,
        status: 'rejected',
        comment: conversion.Conversion.comment,
        sale_amount: conversion.Conversion.sale_amount
      });
    }
  }

  private async assignConversionInUpdatedConversionsToST(conversion: any) {
    const updatedFieldsIndex = this.updatedFields.findIndex(a => a.id == conversion.Conversion.id);
    if (updatedFieldsIndex > -1) {
      this.updatedFields[updatedFieldsIndex].status = conversion.status;
    } else {
      this.uploadedUnmatchedConversionsAssignedToST.unshift({
        affiliate: 2540,
        advertiser_info: conversion.Conversion.advertiser_info,
        status: conversion.Conversion.status,
        comment: conversion.Conversion.comment,
        sale_amount: conversion.Conversion.sale_amount
      });
    }
  }

  trimZeroes(text) {
    for (var i = 0; i < text.length; i++) {
      if (text[i] !== '0') {
        return text.substring(i);
      }
    }
  }

  initTable() {

    this.dataSource.filterPredicate = (data, filter: string) => {
      const accumulator = (currentTerm, key) => {
        return this.nestedFilterCheck(currentTerm, data, key);
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      // Transform the filter by converting it to lowercase and removing whitespace.
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    this.paginator._intl.itemsPerPageLabel = "rozmiar strony:";
    this.paginator._intl.firstPageLabel = "pierwsza strona";
    this.paginator._intl.lastPageLabel = "ostatnia strona";
    this.paginator._intl.previousPageLabel = "poprzednia strona";
    this.paginator._intl.nextPageLabel = "następna strona";
    this.paginator.pageSize = 100;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

  applyFilter(searchedWord: string) {
    this.dataSource.filter = searchedWord.trim();

  }

  private _filterAffiliateOption(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.advertisers ? this.advertisers.filter(option => option.Advertiser.company.toLowerCase().includes(filterValue)) : [];
  }

  private _filterOfferOption(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.offers ? this.offers.filter(option => option.name.toLowerCase().includes(filterValue)) : [];
  }

  async onAdvertiserChanged(advertiser) {
    this.userUploadedConversions = [];
    this.offerId = undefined;
    this.uploadedConversions = "";
    this.showSimulation = false;

    const advertiserId = +this.getTextFromBetween('(', ')', advertiser.option.value);

    if (!this.config.advertisers[advertiserId].Advertiser.offers || !(Object.keys(this.config.advertisers[advertiserId].Advertiser.offers).length > 0) || !(this.config.advertisers[advertiserId].Advertiser.last_offers_update) || this.diffHours(this.config.advertisers[advertiserId].Advertiser.last_offers_update, new Date()) > this.minimalHoursToRefreshData) {
      await this.refreshOffers(advertiserId);
    }

    const offersData = this.config.advertisers[advertiserId].Advertiser.offers;
    this.offers = offersData ? Object.keys(offersData).map(k => offersData[k].Offer) : [];
    this.importConfigurationForm.controls.offer.setValue('');
    this.advertiserId = advertiserId;

    this.initTable();
  }

  async refreshOffers(advertiserId = this.advertiserId) {
    this.refreshingOffers = true;
    if (advertiserId) {
      const responseData = await this.firebaseFunctions.updateOffersInImportConfig(advertiserId);
      this.config.offers = responseData.offers;
    } else {
      this.error = "Nie udało się odświeżyć ofert - brak id reklamodawcy"
    }
    this.refreshingOffers = false;
  }

  onOfferChanged(offer) {
    this.offerId = offer;
  }

  getPreviousMonthStartDate() {
    let x = new Date();
    return new Date(x.getFullYear(), x.getMonth() - 1, 1);
  }

  getPreviousMonthLastDate() {
    let x = new Date();
    x.setDate(0);
    return x;
  }

  openSendDialog() {
    let dialogRef = this.dialog.open(ImportSendDialogComponent, {
      width: '800px',
      data: {
        conversionsToUploadAmount: this.conversionsToUploadDiff?.length ?? 0,
        conversionsToUploadDuplicatesAmount: this.getDuplicatesInConversionsToUploadDiff(),
        conversionsToAddAmount: this.uploadedUnmatchedConversionsAssignedToST?.length ?? 0,
        offersToAddStTo: this.campaignsToBeModified
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        //dodaj wydawców do ofert
        for (let campaignKey of this.campaignsToBeModified) {
          await this.addStAffiliateToOffer(campaignKey.campaign_key, campaignKey.campaign_offer)
        }

        this.uploadConversionsToHasoffers();
      }
    });
  }

  async addStAffiliateToOffer(campaignKey, offerId) {
    try {
      const affiliate = {
        company: "SalesTube",
        date_added:
          "2018-03-15 06:34:49",
        id:
          "2540",
        ref_id:
          null,
        status:
          "active"
      }

      const reqChange: any =
        await this.campaignService.putAffiliateToCampaignOffer(
          campaignKey,
          offerId,
          affiliate
        );

      if (reqChange) this.breadcrumbService.sendMessage(reqChange);
    }
    catch (err) {
      this.breadcrumbService.sendErrorMessage(err.message || err);
    }
  }

  openConversionUploadDialog() {
    let dialogRef = this.dialog.open(ImportConversionDataDialogComponent, {
      height: '1000px',
      width: '1200px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.uploadedConversions = result;

      this.userUploadedConversions = [];

      this.updatedFields = [];

      if (!this.offerId) throw new Error("Przed załadowaniem danych należy wybrać reklamodawcę i ofertę")

      this.errorLog = '';

      this.conversionIdHeaders = this.selectedImportConfig.header_name_ord.split(',');
      this.conversionTransactionHeaders = this.selectedImportConfig.header_name_transaction.split(',');
      this.conversionStatusHeaders = this.selectedImportConfig.header_name_validation_status.split(',');
      this.conversionCommentColumn = this.selectedImportConfig.header_name_comment.split(',');
      this.conversionApprovedAliases = this.selectedImportConfig.validation_status_validated.split(',');
      this.conversionRejectedAliases = this.selectedImportConfig.validation_status_rejected.split(',');
      this.conversionPendingAliases = this.selectedImportConfig.validation_status_waiting.split(',');

      const conversions = this.uploadedConversions;
      if (!conversions) return;
      this.showSimulation = false;
      const rows = conversions.split(/\r?\n/);
      const splittedFile = [];
      rows.forEach(r => splittedFile.push(r.split('\t')));

      const convertedData = [];

      let conversionIdColumn = -1;
      this.conversionIdHeaders.forEach(e => { const index = splittedFile[0].map((a: string) => a.trim().toLowerCase()).indexOf(e.trim().toLowerCase()); if (index >= 0) conversionIdColumn = index; })
      if (conversionIdColumn < 0) this.errorLog += ("Nie można znaleźć nagłówka kolumny z Id konwersji\n");

      let conversionStatusColumn = -1;
      this.conversionStatusHeaders.forEach(e => { const index = splittedFile[0].map((a: string) => a.trim().toLowerCase()).indexOf(e.trim().toLowerCase()); if (index >= 0) conversionStatusColumn = index; })
      if (this.importMode === "automatycznie" && conversionStatusColumn < 0) this.errorLog += ("Nie można znaleźć nagłówka kolumny ze Statusami konwersji\n");

      let conversionCommentColumn = -1;
      this.conversionCommentColumn.forEach(e => { const index = splittedFile[0].map((a: string) => a.trim().toLowerCase()).indexOf(e.trim().toLowerCase()); if (index >= 0) conversionCommentColumn = index; })

      let conversionTransactionColumn = -1;
      this.conversionTransactionHeaders.forEach(e => { const index = splittedFile[0].map((a: string) => a.trim().toLowerCase()).indexOf(e.trim().toLowerCase()); if (index >= 0) conversionTransactionColumn = index; })

      if (this.uploadedConversions.split(/\r\n|\r|\n/).length < 2) this.errorLog += 'Raport musi zawierać rząd nagłówków i co najmniej jeden rząd z danymi\n'

      const allConversionStatuses = [...this.conversionApprovedAliases, ...this.conversionRejectedAliases, ...this.conversionPendingAliases];

      this.validatedReportLines = 0;
      for (let i = 1; i < splittedFile.length; i++) {
        const r = splittedFile[i];


        //walidacja, czy linia jest pusta
        if (r.length === 1 && r[0] === '') {
          this.errorLog += `Wiersz o indeksie ${i + 1} jest pusty\n`;
          continue;
        }

        //walidacja wartości ORD
        if (!r[conversionIdColumn] || r[conversionIdColumn] === '') {
          this.errorLog += `W wierszu o indeksie ${i + 1} brakuje Wartości ORD\n`;
          continue;
        }

        //walidacja statusu konwersji
        if (!r[conversionStatusColumn] || r[conversionStatusColumn] === '' || !allConversionStatuses.includes(r[conversionStatusColumn])) {
          this.errorLog += `W wierszu o indeksie ${i + 1} znajduje się błędna wartość statusu konwersji (${r[conversionStatusColumn]})\n`;
          continue;
        }

        //musi przypisać uniwersalny status 
        convertedData.push({
          Conversion: {
            advertiser_info: r[conversionIdColumn],
            status: this.importMode === "automatycznie" ? this.getClientUploadedConversionStatus(r[conversionStatusColumn]) : this.importMode,
            comment: conversionCommentColumn >= 0 ? r[conversionCommentColumn] : '',
            sale_amount: conversionTransactionColumn >= 0 ? +r[conversionTransactionColumn] : 0,
          }
        });
        this.validatedReportLines += 1;
      }


      if (this.errorLog === '') {
        this.errorLog = 'Załadowano poprawnie'
      } else {
        return;
      };

      splittedFile.shift();

      this.userUploadedConversions = convertedData;
    });
  }

  formatDateComponent(text) {
    let processedText = text.toString();
    if (processedText.length == 1) {
      processedText = '0' + processedText;
    }

    return processedText;
  }

  getChangeText(approvedChange, rejectedChange, isNewConversion = false) {
    if (!approvedChange) {
      approvedChange = 0;
    }
    if (!rejectedChange) {
      rejectedChange = 0;
    }

    const difference = approvedChange - rejectedChange;
    if (!isNewConversion) {
      return (difference != 0) ? `(${difference > 0 ? '+' : ''}${difference})` : '';
    } else {
      if ((approvedChange == 0 && rejectedChange + approvedChange >= 0) || rejectedChange == 0 && rejectedChange + approvedChange <= 0) return '';
      return approvedChange + rejectedChange > 0 ? `(+${approvedChange})` : `(+${-rejectedChange})`;
    }
  }

  getColor(approvedChange, rejectedChange, isNewConversion = false) {
    if (isNewConversion) {
      if (approvedChange || rejectedChange) {
        return { 'more': true }
      }
    } else {
      if(!approvedChange) {
        approvedChange = 0;
      }

      if(!rejectedChange){
        rejectedChange = 0;
      }
      return { 'more': approvedChange > rejectedChange, 'less': approvedChange < rejectedChange }
    }
  }

  async refreshOfferConversions() {
    this.conversionsAggregated = [];
    this.conversionsRaw = undefined;
    this.userUploadedConversions = undefined;
    this.uploadedConversions = "";
    this.errorLog = "";
    this.tableDisplayMode = 'stats';
    this.uploadMessage = '';
    this.showSimulation = false;
    this.uploadedUnmatchedConversionsAssignedToST = [];
    this.onTableModeChange();

    if (!this.offerId) return;
    const formattedStartDate = this.startDate.getFullYear() + "-" + this.formatDateComponent(this.startDate.getMonth() + 1) + "-" + this.formatDateComponent(this.startDate.getDate() + " 00:00:00");
    const formattedEndDate = this.endDate.getFullYear() + "-" + this.formatDateComponent(this.endDate.getMonth() + 1) + "-" + this.formatDateComponent(this.endDate.getDate() + " 23:59:59");
    this.downloadingStats = true;
    const conversionsResponse = await this.firebaseFunctions.getOfferConversions(this.getTextFromBetween('(', ')', this.offerId.option.value), formattedStartDate, formattedEndDate);
    this.downloadingStats = false;
    const conversionsData = conversionsResponse.data.response.data.data;

    this.conversionsRaw = conversionsData;

    if (!this.affiliates) {
      this.downloadingStats = true;
      await this.getAffiliatesList();
      this.downloadingStats = false;
    };

    this.conversionsAggregated = this.aggregateConversions();
    this.tableDisplayMode = 'stats';
    this.onTableModeChange();
  }

  clearData() {
    window.location.reload();
  }

  getTextFromBetween(startChar, endChar, text) {
    const startIndex = text.indexOf(startChar);
    const endIndex = text.indexOf(endChar);
    return text.substring(startIndex + 1, endIndex);
  }

  aggregateConversions(updatedFields: any = []) {
    const holder = {};
    const affiliates = this.affiliates;
    const offer = this.offerId.option.viewValue;
    const conversionsRawList = Object.values(this.conversionsRaw)
    let d: any;
    for (d of conversionsRawList) {
      const updatedConversionStatus = updatedFields.find((uf: any) => uf.id == d.Conversion.id)?.status ?? undefined;

      const status = updatedConversionStatus || d.Conversion.status;

      //nadpisz status ze zmienionych
      if (holder.hasOwnProperty(d.Conversion.affiliate_id)) {
        holder[d.Conversion.affiliate_id][status] = holder[d.Conversion.affiliate_id][status] ? holder[d.Conversion.affiliate_id][status] + 1 : 1;
        holder[d.Conversion.affiliate_id][status + "_change"] =
          holder[d.Conversion.affiliate_id][status + "_change"] ?
            holder[d.Conversion.affiliate_id][status + "_change"] + ((!updatedConversionStatus || updatedConversionStatus == d.Conversion.status) ? 0 : 1) :
            ((!updatedConversionStatus || updatedConversionStatus == d.Conversion.status) ? 0 : 1);
      } else {
        holder[d.Conversion.affiliate_id] = {};
        const index = affiliates.map(a => a.Affiliate.id).indexOf(d.Conversion.affiliate_id);
        holder[d.Conversion.affiliate_id]['affiliate'] = index >= 0 ? affiliates[index].Affiliate.company : "Nie można przypisać wydawcy o id: " + d.Conversion.affiliate_id;
        holder[d.Conversion.affiliate_id]['offer'] = offer;
        holder[d.Conversion.affiliate_id][status] = 1;
        holder[d.Conversion.affiliate_id][status + "_change"] =
          holder[d.Conversion.affiliate_id][status + "_change"] ?
            holder[d.Conversion.affiliate_id][status + "_change"] + ((!updatedConversionStatus || updatedConversionStatus == d.Conversion.status) ? 0 : 1) :
            ((!updatedConversionStatus || updatedConversionStatus == d.Conversion.status) ? 0 : 1);
      }
    };

    const aggregatedConversions = Object.keys(holder).map(k => holder[k]);

    if (this.unmatchedConversionsAssigment == "SalesTube" && this.uploadedUnmatchedConversionsAssignedToST.length > 0) {

      aggregatedConversions.push({
        affiliate: 'SalesTube',
        offer: offer,
        approved: this.uploadedUnmatchedConversionsAssignedToST.filter(c => c.status == 'approved').length,
        approved_change: this.uploadedUnmatchedConversionsAssignedToST.filter(c => c.status == 'approved').length,
        rejected: this.uploadedUnmatchedConversionsAssignedToST.filter(c => c.status == 'rejected').length,
        rejected_change: this.uploadedUnmatchedConversionsAssignedToST.filter(c => c.status == 'rejected').length,
        waiting: this.uploadedUnmatchedConversionsAssignedToST.filter(c => c.status == 'waiting').length,
        waiting_change: this.uploadedUnmatchedConversionsAssignedToST.filter(c => c.status == 'waiting').length,
        hide_change: true,
        message: this.campaignsToBeModified.length ? "Wydawca SalesTube zostanie dodany do kampanii: " + this.campaignsToBeModified.map(c => c.campaign.key).join(',') : ''
      })
    }
    return aggregatedConversions;
  }

  markDuplicatesAsRejected(uploadedConversions) {
    const tempObject = {};
    const conversionDataList = Object.values(this.conversionsRaw)
    let c: any;
    for (c of conversionDataList) {
      if (uploadedConversions.find(uc => uc.id === c.Conversion.id)?.status != 'rejected' ?? true) {
        if (c.Conversion.status != 'rejected' || uploadedConversions.find(uc => uc.id === c.Conversion.id)) {
          if (
            tempObject[c.Conversion.advertiser_info]) {
            this.setConversionInUpdatedConversions(c);
            c.Conversion.status = 'rejected';
          } else {
            tempObject[c.Conversion.advertiser_info] = true;
          }
        }
      }
    };

    return conversionDataList;
  }

  getDuplicatesInConversionsToUploadDiff(){
    let a = 0;
      const tempObject = {};
      let c: any;

      for (c of this.conversionsToUploadDiff) {
        if(!tempObject[c.advertiser_info]){
          tempObject[c.advertiser_info] = true;
        } else{
          a++;
        }
      }
  
      return a;
    }
  

  async getAffiliatesList() {
    if (this.config.affiliates?.length === 0) {
      this.config = (await this.firebaseFunctions.updateAffiliatesInImportConfig()).affiliates;
    }
    const affiliatesData = this.config.affiliates;
    this.affiliates = Object.keys(affiliatesData).map(k => affiliatesData[k]);
  }

  exportParams() {
    return {
      fileName: `${this.offerId.option.viewValue} - statystyki - ${(new Date()).toLocaleString('sv-SE')}`,
      sheet: 'statystyki',
      ignoreEC: true,
      compression: true,
    };
  }

  getAffiliateName(affiliateId) {
    return this.affiliates.filter(a => a.Affiliate.id === affiliateId)[0]?.Affiliate?.company;
  }

  getAdvertiserName(advertiserId) {
    return this.advertisers.filter(a => a.Advertiser.id === advertiserId)[0]?.Advertiser?.company;
  }


  getStatusTotal(cellName) {
    let total = 0;
    let approvedChange = 0;
    let rejectedChange = 0;

    const conversions = this.dataSource?.data ?? [];
    if (!conversions || Object.keys(conversions).length === 0) return 0;
    conversions.map(c => c[cellName]).forEach(c => total = total + (c ? c : 0));

    conversions.filter(c => !c.hide_change).map(c => c["approved_change"]).forEach(c => approvedChange = approvedChange + (c ? c : 0));
    conversions.filter(c => !c.hide_change).map(c => c["rejected_change"]).forEach(c => rejectedChange = rejectedChange + (c ? c : 0));

    const newConversions = conversions.find(c => c.hide_change);
    const newApprovedConversions = newConversions ? newConversions["approved_change"] ? newConversions["approved_change"] : 0 : 0;
    const newRejectedConversions = newConversions ? newConversions["rejected_change"] ? newConversions["rejected_change"] : 0 : 0;

    return cellName == 'approved' ?
      total.toString() + ((approvedChange + newApprovedConversions) > 0 ? ` (${approvedChange - rejectedChange + newApprovedConversions > 0 ? '+' : ''}${approvedChange - rejectedChange + newApprovedConversions})` : '') :
      cellName == 'rejected' ?
        total.toString() + ((rejectedChange + newRejectedConversions) > 0 ? ` (${rejectedChange - approvedChange + newRejectedConversions > 0 ? '+' : ''}${rejectedChange - approvedChange + newRejectedConversions})` : '') :
        total.toString()
  }

  async uploadConversionsToHasoffers() {
    this.uploadingStatsToHo = true;
    this.uploadMessage = "Przygotowuję dane...";
    const hoConversionBatchSize = 40;
    const conversionsToUpload = this.getConversionsToUpload(this.updatedFields, this.conversionsRaw);
    const numberOfBatches = Math.ceil(conversionsToUpload.length / hoConversionBatchSize);
    this.uploadingStatsToHo = true;
    this.uploadMessage = "Wysyłam dane...";
    for (let i = 0; i < numberOfBatches; i++) {

      let urlArguments = "";
      const batch = conversionsToUpload.slice(i * (hoConversionBatchSize), (1 + i) * hoConversionBatchSize - 1);
      batch.forEach(
        c => {
          urlArguments += c.id + ',' + c.status + ',' + (c.comment ? c.comment : '') + ';'
        }
      )

      urlArguments = urlArguments.slice(0, -1);

      if (urlArguments.length > 0) {

        await this.firebaseFunctions.updateConversionsInHasoffers(urlArguments);

        this.breadcrumbService.sendInfoMessage(
          "Wysłano " + ((i < (numberOfBatches - 1) ? 1 + i : 0) * hoConversionBatchSize +
            (i == (numberOfBatches - 1) ? conversionsToUpload.length : 0)).toString() +
          " z " + conversionsToUpload.length.toString() + " konwersji w HO"
        );
      }
    }
    //Tworzenie konwersji
    let urlArguments = "";
    this.uploadedUnmatchedConversionsAssignedToST.forEach(
      c => {
        urlArguments += c.advertiser_info + ',' + c.status + ',' + this.toFloat(c.sale_amount) + ';'
      }
    )

    urlArguments = urlArguments.slice(0, -1);

    if (urlArguments.length > 0) {
      const formattedEndDate = this.endDate.getFullYear() + "-" + this.formatDateComponent(this.endDate.getMonth() + 1) + "-" + this.formatDateComponent(this.endDate.getDate() + " 12:00:00");
      const aa = await this.firebaseFunctions.createConversionsInHasoffers(urlArguments, this.getTextFromBetween('(', ')', this.offerId.option.value), formattedEndDate);

      this.breadcrumbService.sendInfoMessage(
        "Liczba konwersji utworzona w HO: " + this.uploadedUnmatchedConversionsAssignedToST.length
      );
    }
    //Utwórz konwersje

    this.refreshOfferConversions();
    this.uploadingStatsToHo = false;
  }

  getConversionsToUpload(changedConversions, rawConversions) {
    const conversionsToUploadToHo = [];
    let a = Object.keys(rawConversions).map(k => rawConversions[k].Conversion);
    for (let i = 0; i < changedConversions.length; i++) {
      let index = a.findIndex(x => x.id == changedConversions[i].id);
      if (index != -1) {
        const currentConversionFromHo = a[index].status;
        const currentChangedConversion = changedConversions[i].status;

        if (currentConversionFromHo != currentChangedConversion) {
          conversionsToUploadToHo.push(changedConversions[i]);
        }
      } else {
        conversionsToUploadToHo.push(changedConversions[i]);
      }
    }
    return conversionsToUploadToHo;
  }

  getClientUploadedConversionStatus = (conversionStatus) => this.conversionApprovedAliases.includes(conversionStatus) ? 'approved' : this.conversionRejectedAliases.includes(conversionStatus) ? 'rejected' : this.conversionPendingAliases.includes(conversionStatus) ? 'pending' : 'error';

  onSubmit = () => { }

  clearOfferValue() {
    this.offerId = undefined;
    this.offerValue = ''
  }

  clearAdvertiserValue() {
    this.advertiserId = null;
    this.advertiserValue = '';
    this.clearOfferValue();
  }

  toFloat(input) {

    if (typeof input === 'string' || input instanceof String) {
      input = input.replace(',', '.');
    }
    return +input;
  }
}
