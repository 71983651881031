import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class PublisherService {

  constructor(private firestore: AngularFirestore) { }
  //campaignOfferCollection = this.firestore.collection('campaign_offer');
  affiliateCustomCollection = this.firestore.collection('affiliate_custom');

  async getAffiliateOffers(startMonth, startYear, endMonth, endYear, refId) {

    const yearDiffrence = +endYear - +startYear;
    const monthDiffrence = +endMonth - +startMonth;
    const monthAmount = monthDiffrence + (yearDiffrence * 12) + 1;

    // let a = 0;

    // for(a =0; a < monthAmount; a++) {

    // }

    // const startMonthYear = startYear * 100 + startMonth;
    // const endMonthYear = endYear * 100 + endMonth;

    // const offers = await this.affiliateCustomCollection.ref
    //   .where(`${ref}00${startMonth}`, ">", `${startYear}00${startMonth}`)
    //   .orderBy("campaign.data_rozpoczecia", "asc")
    //   .where("campaign.data_rozpoczecia", "<", `${endYear}00${endMonth}`)
    //   .get();


    const docOfferIds = [];
    const campaignOffers = [];

    //const nextMonth = this.nextMonth(month);
    //const offers =

    // await this.affiliateCustomCollection.ref
    //   .where("campaign.data_rozpoczecia", ">", `${startYear}-${startMonth}`)
    //   .orderBy("campaign.data_rozpoczecia", "asc")
    //   .where("campaign.data_rozpoczecia", "<", `${endYear}-${endMonth}`)
    //   .get()

    // offers.docs.forEach(d => docOfferIds.push(d.ref));

    let i = 0;
    for (i = 0; i < monthAmount; i++) {
      const currentMonth = +startMonth + i - (12 * parseInt((i / 12).toString()));
      const currentYear = +startYear + parseInt(((+startMonth + i - 1) / 12).toString());
      const monthYearRef = this.affiliateCustomCollection.doc(refId).collection("month_year").doc(`${currentYear}00${this.getMonthString(currentMonth)}`).ref;
      const campaignOfferData = (await monthYearRef.get()).data();
      const offers = campaignOfferData?.campaign_offers;
      if (offers) {
        const campaignOffersList = Object.keys(offers).map(k => offers[k]);
        campaignOffersList.forEach(o => campaignOffers.push(o));
      }
    }

    return campaignOffers;
  }

  getMonthString(month) {
    let newMonthString = month.toString();
    if (newMonthString.length == 1) {
      newMonthString = "0" + newMonthString
    }

    return newMonthString;
  }

  getMonthYear = (campaign: any, a: any) => "affiliate_custom/" + a.ref_id + "/month_year/" + campaign.data_rozpoczecia.substring(0, 4) + "00" + campaign.data_rozpoczecia.substring(5, 7);
}
