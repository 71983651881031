import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
var md5 = require('md5');
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class FileuploadService {

  constructor(private httpClient: HttpClient) { }

  TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3N1ZXIiOiJwcnplbXlzbGF3Lmd3YXJla0Bncm91cG9uZS5jb20ucGwiLCJpdGVtX29wZXJhdGlvbnMiOlsiYWxsIl0sInByb2plY3RfbmFtZSI6IkFmZmlsaWF0ZUV4cGVydCIsInJvb3RfcGF0aCI6ImFmZmlsaWF0ZV9leHBlcnQiLCJjdXN0b20iOiJ7fSIsInZhbGlkX3RvIjoiMjAyOS0wMS0wMVQwNzozNTo1MC4wMDhaIiwiZG93bmxvYWRfdXJsX2V4cGlyYXRpb25fdGltZSI6NDgwLCJ0b2tlbl9pZCI6MzMsImlhdCI6MTY0NTYwMTgwOH0.VsWPf05p878358T5WBRvxHNSMFcCFANv8z1Vha0BgO8'
  
  //prod: https://us-central1-repozytorium-g1.cloudfunctions.net/
  //local: http://localhost:5001/repozytorium-g1/us-central1/
  prefix = 'https://us-central1-repozytorium-g1.cloudfunctions.net/'

  headerConfig = {
    'Authorization': `Bearer ${this.TOKEN}`
  }

  postItem(fileToUpload: File, campaignKey, month, offerId, affiliateRefId, isCustomAdded): Observable<any> {
    const fileName = this.getFileName(campaignKey, month, offerId, affiliateRefId, fileToUpload.name, isCustomAdded);
    const customData = `{ campaignKey: ${campaignKey}, month: ${month}, offerId: ${offerId}, affiliateRefId: ${affiliateRefId} }`;
    const encodedCustomData = encodeURIComponent(customData.toString());
    const endpoint = this.prefix + 'repository_api/push_item/' + fileName + "?" + encodedCustomData;
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileName);
    return this.httpClient
      .post(endpoint, formData, { headers: this.headerConfig })
      .pipe(map((response) => { return response; }))
  }

  deleteItem(campaignKey, month, offerId, affiliateRefId, fileName, isCustomAdded): Observable<any> {
    const repositoryFileName = this.getFileName(campaignKey, month, offerId, affiliateRefId, fileName, isCustomAdded);
    const endpoint = this.prefix + 'repository_api/delete_item/' + repositoryFileName;
    return this.httpClient
      .post(endpoint, {}, { headers: this.headerConfig })
      .pipe(map((response) => { return response; }))
  }

  downloadItem(campaignKey, month, offerId, affiliateRefId, fileName: string, isCustomAdded): Observable<any> {
    const repositoryFileName = this.getFileName(campaignKey, month, offerId, affiliateRefId, fileName, isCustomAdded);
    const endpoint = this.prefix + 'repository_api/get_item/' + repositoryFileName;

    return this.httpClient
      .get(endpoint, { headers: this.headerConfig, responseType: 'arraybuffer' })
      .pipe(map((response) => { return response; }))
  }

  getFileName(campaignKey, month, offerId, affiliateRefId, uploadedFileName, isCustomAdded) {
    const prefix = isCustomAdded ? 'custom_': '';
    return `${campaignKey}-${month}-${offerId}-${affiliateRefId}-${md5(campaignKey + month + offerId + "97*&dNDIN" + affiliateRefId)}-${prefix+uploadedFileName}`;
  }
}
