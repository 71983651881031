import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { AuthGuardGuard } from '../auth-guard.guard';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  errorMessage: string;
  constructor(
    private authService: AuthService,
    private authGuard: AuthGuardGuard,
    private route: ActivatedRoute,
  ) { }

  activeTabIndex = 0;

  ngOnInit() {
    this.route.queryParams
      .filter(params => params.l)
      .subscribe(params => {

        let l = params.l;
        this.activeTabIndex = l == 'affiliate' ? 1 : 0;
      });

    this.authGuard.currentMessage.subscribe(
      msg => {
        if (msg) { this.errorMessage = 'wymagana autoryzacja do: ' + msg; }
        this.errorMessage = null;
      });
  }

  loginWithGoogle() {
    this.authService.loginWithGoogle();
  }

  loginWIthHasOffers(formLogin: NgForm) {
    this.authService.loginAsHoUser(formLogin.value.email, formLogin.value.password);
  }

  zaloguj(formLogin: NgForm) {
    this.authService.login(formLogin.value.email, formLogin.value.password);
  }

  zarejestruj(formLogin: NgForm) {
    this.errorMessage = 'rejestracja czasowo wyłączona';
    return;
    this.authService.signup(formLogin.value.email, formLogin.value.password);
  }
}