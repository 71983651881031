import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { database } from 'firebase';

export interface DialogData {
  title: string;
  dialogMessage: string;
  showMessage: boolean;
  showSpinner: boolean;
  doCancel: boolean;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {
  showSpinner;
  showMessage;
  doCancel;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.showSpinner = data.showSpinner;
    this.showMessage = data.showMessage;
    this.doCancel = data.doCancel ?? true;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}
