import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class LabelAdminService {
  labelAdminTableName = "label_admin";
  labelAdminTableRef = this.db.collection(this.labelAdminTableName);


  getPermissions:any = (email:string) =>{
    return this.labelAdminTableRef.doc(email).valueChanges();
  }

  constructor(private db: AngularFirestore) { }
}