import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class BreadcrumbService {
  public menuObs: Observable<MenuItem[]>;
  public menuList: MenuItem[] = [{ name: 'home', caption: 'home', path:'/', children: [] }];
  public errorMessage = new BehaviorSubject<string>('');
  public infoMessage = new BehaviorSubject<string>('');
  public warningMessage = new BehaviorSubject<string>('');

  constructor() { 
    this.initMenu();
  }
  initMenu() {

  }

  public sendMessage(info: ResponseInfo) {
    if(info.status === 'error') {
      this.errorMessage.next(info.message);
    }
    else {
      this.infoMessage.next(info.message);
    }
  }

  public sendErrorMessage(errorMsg) {
    this.errorMessage.next(errorMsg);
  }

  public clearErrorMessage() {
    this.errorMessage.next(null);
  }
  
  public sendInfoMessage(infoMsg) {
    this.infoMessage.next(infoMsg);
  }

  public sendWarningMessage(infoMsg) {
    this.warningMessage.next(infoMsg);
  }

  public clearInfoMessage() {
    this.infoMessage.next(null);
  }

}

export interface ResponseInfo {
  status: 'success' | 'ok' | 'error';
  message: string;
}

export interface MenuItem {
  name: string;
  caption: string;
  path: string;
  children: MenuItem[];
}