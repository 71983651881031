import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FsdbService {
  private subs:any = {}
  public menu_items: any = {};
  public menuItemsSub = new BehaviorSubject({});
  public offers: any = {};
  public offersSub = new BehaviorSubject({});

  constructor(private db: AngularFirestore) { 
    this.initSubscriptions();
  }
  initSubscriptions() {
    this.initFrameDataSub();
  }

  initFrameDataSub() {
    this.db.collection('ho_data').doc('frame_data').snapshotChanges().pipe(
      map(snap => snap.payload.data())).subscribe(doc => {
        this.menu_items = doc;
        this.menuItemsSub.next(this.menu_items.data);
      }
    );    
  } 

  public getOfferData(offer_id) {
    return this.db.doc(`offers/${offer_id}`).snapshotChanges();
  }

  async getOffer(offer_id) {
    return await this.db.collection('offers').doc(`${offer_id}`).get().toPromise().then(d => d.data()).then(d => d);
  }

  public async newCampaign(offer_id:number, campaign_attributes:any) {
    try {
      if(!offer_id) throw new Error('brak danych oferty');
      if(!campaign_attributes) throw new Error('brak atrybutów nowej kampanii');
      const offerRef = this.db.collection('offers').doc(`${offer_id}`);
      const offer:any = await offerRef.get().toPromise().then(d => d.data());
      const campaigns = offer.campaigns;
      let campaign_id = 1;

      if(Object.keys(campaigns).length) {
        campaign_id = Math.max(...Object.keys(campaigns).map(k => +k)) + 1;
      }

      const camp: any = campaign_attributes;
      camp.id = campaign_id;
      camp.offer_id = offer.id;
      camp.advertiser_id = offer.advertiser_id;
      camp.offer_name = offer.name;
      camp.advertiser_name = offer.advertiser_name;
      offer.campaigns[campaign_id] = camp;

      await offerRef.set(offer); 
      return {
        status: 'success',
        message: 'Nowa kampania została utworzona: ' + ((camp.name) || ''),
        campaign: camp
      };      
    }
    catch(err) {
      return {
        status: 'error',
        message: err.message || err
      };
    }
   
  }
}