import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { BreadcrumbService } from '../environment/breadcrumb.service';
import { FbFunctionsService } from '../environment/fb-functions.service';
import { ImportConfiguration } from "../models/ImportConfiguration";
import { ImportDataConfiguration } from "../models/ImportDataConfiguration";

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  affiliateRefreshPeriodInHours = 4;

  constructor(private firestore: AngularFirestore, private fbFunctionService: FbFunctionsService, private breadcrumb: BreadcrumbService, private authService: AuthService) { }

  importFirestoreRef = this.firestore.collection("import").doc("settings");

  async getConfig(): Promise<ImportConfiguration> {
    let importData = (await this.importFirestoreRef.ref.get()).data() as ImportConfiguration;
    const now = new Date();
    const lastRefresh = new Date(importData.last_affiliate_update.seconds*1000);
    if(this.authService.getUserEmail().endsWith("@groupone.com.pl") && ((Math.abs(now.getTime() - lastRefresh.getTime()) / 36e5) > this.affiliateRefreshPeriodInHours)){

    this.breadcrumb.sendWarningMessage("Ładowanie danych. Proszę czekać...");
      await this.fbFunctionService.updateAffiliatesInImportConfig();
      importData = (await this.importFirestoreRef.ref.get()).data() as ImportConfiguration;
      this.breadcrumb.sendInfoMessage("Pomyślnie załadowano dane");
    }

    return importData;
  }

  getConfig$(): Observable<any> {
    const importData = this.importFirestoreRef.snapshotChanges();
    return importData;
  }

  /**
   * [Google]{@link http://www.google.com}
   * 
   * @param newImportDataConfiguration 
   */
  async saveNewImportConfigurationToConfig(newImportDataConfiguration: ImportDataConfiguration): Promise<void> {
    const importData = (await this.importFirestoreRef.ref.get()).data() as ImportConfiguration;

    if (!importData.import_configurations) {
      importData.import_configurations = {};
    }

    const newKey = Math.max(...[0,...Object.keys(importData.import_configurations).map(k => +k)]) + 1;
    importData.import_configurations[newKey] = newImportDataConfiguration;

    await this.importFirestoreRef.set(importData);
  }

  async editImportConfigurationInConfig(key: number, importDataConfiguration: ImportDataConfiguration): Promise<void> {
    const importData = (await this.importFirestoreRef.ref.get()).data() as ImportConfiguration;

    if (!importData.import_configurations) {
      throw new Error(`Błędne lub uszkodzone dane o konfiguracji importu`);
    }

    if (!importData.import_configurations[key]) {
      throw new Error(`konfiguracja o id: ${key} nie została znaleziona`);
    }

    importData.import_configurations[key] = importDataConfiguration;

    await this.importFirestoreRef.set(importData);
  }

  async deleteImportDataConfigurationFromConfig(id: number): Promise<void> {
    const importData = (await this.importFirestoreRef.ref.get()).data() as ImportConfiguration;

    if (!importData.import_configurations) {
      return;
    }

    delete importData.import_configurations[id]

    await this.importFirestoreRef.set(importData);
  }

}
