import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenAuthService {

  apiToken: string = 'v1';
  baseUrl = environment.affiliateExpertFunctionsBasePath;
  
  constructor(private http: HttpClient) { }

  public login(token: string): Promise<any> {
    const url = this.baseUrl + `authapi/login/${this.apiToken}`;

    return this.http.post(url, { token },
      {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
        observe: 'response'
      }).toPromise();
  }
}