import { Component, OnInit, Inject } from '@angular/core';
import { Form, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { BreadcrumbService } from 'src/app/environment/breadcrumb.service';
import { ImportDataConfiguration } from "src/app/models/ImportDataConfiguration";
import { ImportService } from '../../import.service';

@Component({ template: '' })
export class EditImportDataConfigurationEntryComponent {
  offer_urls: any = {};
  affiliate_id = this.route.snapshot.params["affiliateId"];
  isCustomAdded = this.route.snapshot.params["isCustomAdded"] === 'custom';
  constructor(
    private breadcrumbs: BreadcrumbService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private importService: ImportService) {
    try {
      this.openCreateNewImportDataConfigurationDialog();
    }
    catch (err) {
      let message = err?.message;
      this.breadcrumbs.sendErrorMessage(message);
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  roundNumber(num, dec) {
    return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
  }

  private async getConfigurationData(id: number) {
    const config = await this.importService.getConfig()

    if (!config.import_configurations) {
      this.breadcrumbs.sendErrorMessage("Błędne dane konfiguracji importu");
      return;
    } else if (!config.import_configurations[id]) {
      this.breadcrumbs.sendErrorMessage(`Brak danych dla konfiguracji importu o id: ${id}`);
      return;
    }

    return { id, ...config.import_configurations[id] };
  }

  public async openCreateNewImportDataConfigurationDialog() {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation.extras.state ? navigation.extras.state : await this.getConfigurationData(navigation.id);

    let dialogData = {
      current_user: this.authService.getUserEmail(),
      data
    };

    const dialogRef = this.dialog.open(EditImportDataConfigurationComponent, {
      width: '900px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(async (result: { operation_type: string, data: ImportDataConfiguration & any }) => {
      if (result && result.operation_type === 'update') {
        await this.importService.editImportConfigurationInConfig(result.data.id, result.data);
      } else if (result && result.operation_type === 'delete') {
        await this.importService.deleteImportDataConfigurationFromConfig(result.data.id);
      }
      this.router.navigate(['../'], { relativeTo: this.route });
    })
  }

}

@Component({
  selector: 'edit-import-data-configuration',
  templateUrl: './edit-import-data-configuration.component.html',
  styleUrls: ['./edit-import-data-configuration.component.css']
})
export class EditImportDataConfigurationComponent implements OnInit {
  userEmail: string;
  id: number;
  newImportDataConfigurationForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<EditImportDataConfigurationComponent>,
    public dialog: MatDialog,
    private breadcrumbsService: BreadcrumbService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.userEmail = data.current_user;
    this.id = data.data.id;

    this.newImportDataConfigurationForm = new FormGroup({
      name: new FormControl(data.data.name, Validators.required),
      created_by: new FormControl(data.data.created_by, Validators.required),
      created_when: new FormControl(data.data.created_when),
      edited_by: new FormControl(this.userEmail, Validators.required),
      edited_when: new FormControl(new Date()),
      header_name_ord: new FormControl(data.data.header_name_ord, Validators.required),
      header_name_validation_status: new FormControl(data.data.header_name_validation_status, Validators.required),
      header_name_transaction: new FormControl(data.data.header_name_transaction),
      header_name_comment: new FormControl(data.data.header_name_comment),
      validation_status_validated: new FormControl(data.data.validation_status_validated, Validators.required),
      validation_status_waiting: new FormControl(data.data.validation_status_waiting),
      validation_status_rejected: new FormControl(data.data.validation_status_rejected, Validators.required),
      comment: new FormControl(data.data.comment),
    });
  }

  ngOnInit() { }


  Confirm() {
    if (this.newImportDataConfigurationForm.valid) {
      this.dialogRef.close({ operation_type: "update", data: ({ id: this.id, ...this.newImportDataConfigurationForm.value }) });
    } else {
      this.breadcrumbsService.sendErrorMessage("Przed utworzeniem nowej konfiguracji należy wypełnić wszystkie wymagane pola");
    }
  }

  Delete() {
    this.dialogRef.close({ operation_type: "delete", data: ({ id: this.id, ...this.newImportDataConfigurationForm.value }) });
  }

  Close(): void {
    this.dialogRef.close();
  }
}
